<template lang="pug">

  .overview

    

    .row(v-if="topics && topics.length > 0")
      .col-4
        b-input-group(horizontal)
          b-select(v-model="sortByTop", :options="[{'value': 5, 'text': 'Top 5'},{'value': 10, 'text': 'Top 10'},{'value': 15, 'text': 'Top 15'}]", style="max-width: 150px" size="sm").mr-2
          //b-select(v-model="orderBy", :options="[{'value': 'score', 'text': 'Relevance'},{'value': 'pub_date', 'text': 'Date'}]", style="max-width: 150px" size="sm").mr-2
          b-btn(@click="info_show = !info_show", variant="outline-primary", size="sm")
            .fa.fa-info-circle
          b-btn(@click="desc_show = !desc_show", variant="outline-primary", size="sm").ml-1
            .fa.fa-align-justify

      .col-8
        .float-right

          b-form(inline)
            b-form-group().mr-5
              
            
              b-input-group(horizontal).d-flex.align-items-center
                b-form-checkbox(v-for="(value, filter) in filters" v-model="filters[filter]", :value="true", :unchecked-value="false", @change="getFollowTopic()").mr-2 
                  | {{ucfirst(filter)}}
                 

              //b-btn(size="sm" :variant="(filters.explainer ? 'secondary' : 'outline-secondary')", @click="toggleFilter('explainer')", href="javascript:void(0)").mr-1 Explainer
              //b-btn(size="sm" :variant="(filters.launches ? 'secondary' : 'outline-secondary')", @click="toggleFilter('launches')", href="javascript:void(0)").mr-1 Product launches
              //b-btn(size="sm" :variant="(filters.management ? 'secondary' : 'outline-secondary')", @click="toggleFilter('management')", href="javascript:void(0)").mr-1 Management
              //b-btn(size="sm" :variant="(filters.investment ? 'secondary' : 'outline-secondary')", @click="toggleFilter('investment')", href="javascript:void(0)").mr-1 Investments
              //b-btn(size="sm" :variant="(filters.partner ? 'secondary' : 'outline-secondary')", @click="toggleFilter('partner')", href="javascript:void(0)").mr-1 Partner
             
              //b-btn(size="sm" :variant="(filters.research ? 'secondary' : 'outline-secondary')", @click="toggleFilter('research')", href="javascript:void(0)").mr-1 Research / Studies
              //b-btn(size="sm" :variant="(filters.operations ? 'secondary' : 'outline-secondary')", @click="toggleFilter('operations')", href="javascript:void(0)").mr-1 Operating metric
              //b-btn(size="sm" :variant="(filters.others ? 'secondary' : 'outline-secondary')", @click="toggleFilter('others')", href="javascript:void(0)").mr-1 Others

            
            b-select(v-model="sortBy", :options="[{'value': 7, 'text': 'Last week'}, {'value': 31, 'text': 'Last month'}, {'value': 90, 'text': 'Last quarter'}]", size="sm" @change="getFollowTopic()").ml-1
    
    b-overlay(:show="$store.state.loading").w-100.mt-3
      b-row(cols="1" cols-sm="1" cols-md="2" cols-lg="3").mt-2.form-row
          //b-col().mb-2 
            
            b-card(no-body)
              b-card-header()
                    | New topic
              b-card-body.h-mouseover-parent.text-center
                    
                    h1.mt-3.mb-3
                      b-link(:to="'/topics/add_topic'" )  
                        i.fas.fa-plus-circle()
          div(v-if="topics && topics.length == 0").text-muted.mt-3
            | You do not follow any topics yet.
            | Go to the 
            b-link(to="/topics/") topic section 
            | and follow some topics.
          
          b-col(v-for="topic in $_.orderBy(topics, item => (sortBy == 'trend_media_attention' ? item.custom_data.trend_media_attention.growth : item.name), ['desc'])").d-flex.align-items-stretch.mb-3.w-100
            
              
                  b-card(no-body).w-100
                    b-card-header()
                      .d-flex.align-items-center.justify-content-between
                        router-link(:to="'/news/details/' + topic._id + ''")
            
                          b.truncate-2 {{topic.name}}
                            b-link().ml-2.text-muted(href="javascript:void(0)")
                              i.fas.fa-external-link-alt
                        b-row
                          b-col(cols="1" cols-sm="1" cols-md="2" cols-lg="3")
                            //.d-flex.justify-content-between.align-items-center.small
                          
                              .score(v-if="topic.custom_data.trend_media_attention", :style="{'background-color': heatMapColorforValue((topic.custom_data.trend_media_attention.growth+0.3+1)/2)}").text-center <span v-if="topic.custom_data.trend_media_attention.growth-70 > 0">+</span>{{Math.round(topic.custom_data.trend_media_attention.growth*100+30)}}%
                                .small Media trend
                              
                              .score(v-if="topic.custom_data.trend_funding", :style="{'background-color': heatMapColorforValue((topic.custom_data.trend_funding.growth+0.3+1)/2)}").text-center <span v-if="topic.custom_data.trend_funding.growth-70  > 0">+</span>{{Math.round(topic.custom_data.trend_funding.growth*100+30)}}%
                                .small Funding trend

                    b-card-body
                      .text-center
                        b-spinner(v-if="topic.relations.news == null")
                      div(id="news-list-group", v-if="topic.relations.news && topic.relations.news.entries.length > 0")
                     
                        div(v-for="entry in topic.relations.news.entries.slice(0, sortByTop)").h-mouseover-parent

                            .float-right
                              
                              b-btn(variant="link", v-if="in_newsletter(entry)", @click="remove_newsletter(entry)")
                                <i class="fa fa-bookmark"></i>
                              
                              b-dropdown(size="sm", no-caret, variant="link", style="padding: 5px;", right, v-if="!in_newsletter(entry)").h-mouseover-show
                                
                                template(#button-content)
                                  i.fas.fa-ellipsis-v
                                b-dropdown-item(@click="add_newsletter(entry)")
                                  i.fa.fa-bookmark.mr-1
                                  | Add to curator  
                                b-dropdown-item(@click="fewer()")
                                  i.far.fa-thumbs-down.mr-1 
                                  | Fewer news like this

                              div(style="width: 33px;" v-if="!in_newsletter(entry)").h-mouseover-hide
                           
                            .media.mt-0(v-if="entry.doc_type == 'FeedEntryFunding'")
                              
                              a(:href="entry.link")
                                    img(:src="'https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/crunchbase.com'" class="mr-1").feed-image-news

                              .media-body()
                                a(:href="'/ecosystem/lookup/' + entry.source_data.related_organization.web_entity + '?source=news'", :disabled="!entry.source_data.related_organization.web_entity") 
                                  .mb-1.small {{entry.title}}

                                .text-muted.small(v-if="entry.text") {{entry.text}}
                                //.text-muted.small(v-if="entry.investor_list") {{entry.investor_list.join(", ")}}
                                //.text-muted.small {{entry.source_data.announced_on | utcFrom}} &mdash; <a :href="entry.source_data.cb_url" target="_blank">Crunchbase</a>

                            .media.mt-0(v-if="entry.doc_type == 'FeedEntryNews'").mt-3
                               
                                a(:href="entry.link")
                                    div(class="feed-image-news" :style="'background-color: #dee2e6; background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + entry.source + ') center 0% / cover no-repeat;'").mr-2

                                .media-body()
                                    a(:href="entry.link", target="_blank")
                                     
                                        .truncate-2(style="font-size: 0.875em") {{entry.title}}
                                      
                                        template(v-if="entry.source_data && entry.source_data.highlights")
                                          .text-muted.small.desc(v-for="e in entry.source_data.highlights.full_text" v-html="e", v-show="desc_show")
                                 
                                        .text-muted.small(v-if="info_show") {{entry.created_at | utcFrom}} &dash; {{entry.source}} &dash; {{Math.round(entry.source_data.score[2]*100)}}
                                        
                                        span.mr-1(v-show="desc_show")
                                          .text-muted.small(v-if="entry.categories && entry.categories['investment'] > 0.5") Investment
                                          .text-muted.small(v-if="entry.categories && entry.categories['explainer'] > 0.5") Explainer
                                          .text-muted.small(v-if="entry.categories && entry.categories['launch'] > 0.5") Launch
                                          //.text-muted.small(v-if="entry.categories && entry.categories['study'] > 0.5") Study
                                          //.text-muted.small(v-if="entry.categories && entry.categories['operations'] > 0.5") Operating metric
                                      
                        div(v-if="topic.relations.sections['foundings'] && topic.relations.sections['foundings'].length").mt-3 
                          hr
                          .text-muted.small New companies
                          
                          div(v-for="entry in topic.relations.sections['foundings'].slice(0,3)")
                            
                            .media.mt-0().mt-3
                              
                                a(:href="entry.org_url")
                                    div(class="feed-image-news" :style="'background-color: #dee2e6; background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + entry.web_entity + ') center 0% / cover no-repeat;'").mr-2

                                .media-body()
                                  a(:href="entry.org_url").small {{entry.org_name}} 
                                  .small.truncate-2.text-muted.small {{entry.short_description}}
                        
                        div(v-if="topic.relations.sections['funding_rounds'] && topic.relations.sections['funding_rounds'].length").mt-3 
                          hr
                          .text-muted.small New funding rounds
                          div(v-for="entry in topic.relations.sections['funding_rounds'].slice(0,3)")
                           
                            .media.mt-0().mt-3
                              
                                a(:href="entry.org_url")
                                    div(class="feed-image-news" :style="'background-color: #dee2e6; background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + entry.web_entity + ') center 0% / cover no-repeat;'").mr-2

                                .media-body()
                                 
                                  a(:href="entry.org_url").small {{entry.org_name}} - {{ucfirst(entry.investment_type.replace("_", " "))}} - {{entry.value}}
                                  .small.truncate-2.text-muted.small {{entry.short_description}} 
                                  .text-muted.small(v-if="info_show") {{entry.date | utcFrom}}

                        div(v-if="topic.relations.sections['aquisitions'] && topic.relations.sections['aquisitions'].length").mt-3 
                          hr
                          .text-muted.small Acquistions
                          div(v-for="entry in topic.relations.sections['aquisitions'].slice(0,3)")
                            .media.mt-0().mt-3
                              
                                a(:href="entry.org_url")
                                    div(class="feed-image-news" :style="'background-color: #dee2e6; background: url(https://images.hytechnologies.co/unsafe/100x100/logo.clearbit.com/' + entry.web_entity + ') center 0% / cover no-repeat;'").mr-2

                                .media-body()
                                  a(:href="entry.org_url").small {{entry.org_name}} 
                                  .small.truncate-2.text-muted.small {{entry.short_description}}
                                  .text-muted.small(v-if="info_show") {{entry.date | utcFrom}}


    //.row.mt-5(v-if="topics && topics.length > 0")
      .col-8
        label Upcoming Keywords (auto-discovered)
      .col-4
        .float-right
          b-form(inline)

        
</template>

<script>
import Vue from 'vue';
import Config from '@/config'

import ProjectCard from '@/components/ProjectCard.vue'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'ProjectOverview',
  props: {
 
    "show_industries": {
      type: Boolean,
      default: true
    }, 
    "project": {
      type: Object,
      default: null
    }
  },


  data: function () {
      return {
        filters: {
          "explainer": true,
          "launch": true,
          //"management": true,
          "investment": true,
          //"acquistions": true,
          //"research": true,
          //"operations": true,
          "partner": true,
          "others": true
        },
        desc_show: false,
        info_show: true,
        industries: null,
        selectedIndustry: null,
        topics: null,

        sortBy: 7,
        orderBy: '-<score>',
        sortByTop: 10
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    this.getFollowTopic();
    this.getNewsletter();

    if (localStorage.desc_show) {
      this.desc_show = localStorage.desc_show == "true";
    }
    if (localStorage.info_show) {
      this.info_show = localStorage.info_show == "true";
    }
    if (localStorage.sortBy) {
      this.sortBy = localStorage.sortBy;
    }
     if (localStorage.sortByTop) {
      this.sortByTop = localStorage.sortByTop;
    }
     if (localStorage.filters) {
      this.filters = localStorage.filters;
    }
    
  },

  watch: {

    'desc_show': function(val) {
      localStorage.desc_show = val;
    },
     'info_show': function(val) {
      localStorage.info_show = val;
    },
     'sortBy': function(val) {
      localStorage.sortBy = val;
    },

    'sortByTop': function(val) {
      localStorage.sortByTop = val;
    },

    'filters': function(val) {
      localStorage.filters = val;
    },
    
    
    'results': function () {
      this.results.forEach(it => {
        console.log(it);
      });
      
    },
    
  },

  computed: {
       
        ...mapFields([
            'topics.results',
            'topics.trends',
            'sidebar_project.selected_item',

        ]),
  },

  
  methods: {

    ucfirst(text) {
      if (!text) return text;
      return text[0].toUpperCase() + text.slice(1);
    },

    toggleFilter(name) {
      this.filters[name] = !this.filters[name];
      localStorage.filters = this.filters;
      this.getFollowTopic();
    },

    in_newsletter(entry) {
      
      return this.newsletter.items.map((it)=>{return it.link}).indexOf(entry.link) > -1;
    },

    remove_newsletter(entry) {
      this.newsletter.items = this.newsletter.items.filter(it => it.link != entry.link);
      this.save_newsletter(this.newsletter);
      this.$forceUpdate();
    },

    add_newsletter(entry) {
     
      this.newsletter.items.push(entry);
      this.save_newsletter(this.newsletter);
      this.$bvToast.toast("Added to curator newsletter.", {
            title: 'Added',
            variant: 'success',
            autoHideDelay: 1000,
      });
      this.$forceUpdate();
    },

    fewer() {
    
      this.$bvToast.toast("We will improve the selection in the future.", {
            title: 'Thank you',
            variant: 'success',
            autoHideDelay: 1000,
      });
      this.$forceUpdate();
    },

    save_newsletter(nl) {

      if(!nl) {
        nl = {"_id":  "newsletter:" + this.$store.state.user.id};
      }

      Vue.http.put(`${Config.config.base_host}/newsletters/${nl._id}`, nl).then((res) => {
           
            this.newsletter = res.body;
      });

    },

    getNewsletter(nl_id = null) {
        if(!nl_id) nl_id = "newsletter:" + this.$store.state.user.id;
        this.$store.dispatch('start_loading');
        Vue.http.get(`${Config.config.base_host}/newsletters/${nl_id}`).then((res) => {
            this.newsletter = res.body;
            this.$store.dispatch('stop_loading');
      });
    },
    


    heatMapColorforValue(percentage, hue0 = 120, hue1 = 0) {
      var hue = ((1 - percentage) * (hue1 - hue0)) + hue0;
      return 'hsl(' + hue + ', 100%, 50%, 0.2)';
    },

    delete_project_topic(topic) {
   
      topic.relations.pt = topic.index_space.projects.find(x => x._id == this.project._id)

       let pt = {"_id": this.project._id + '::' + topic._id, "deleted_at": true, "group":  topic.relations.pt.group, "custom_data": {},"stage": topic.relations.pt.stage};

       Vue.http.put(`${Config.config.base_host}/project_topics/${pt._id}`, pt).then(() => {
          this.getTopics();
          this.$store.dispatch("stop_loading");

        });
    },

    openTopicSidebar(topic){
      console.log(topic.index_space);
      topic.relations.pt = topic.index_space.projects.find(x => x._id == this.project._id)
      
      if(!topic.relations) topic.relations = {};
      topic.relations.evaluate = true;
      topic.relations.project = this.project;
      this.selected_item = topic;
    },

      getTrends(topic_id) {
        this.$store.dispatch("start_loading");
         Vue.http.get(Config.config.base_host+'/topics_v1/media_attention/'+topic_id).then(resp => {
            this.trends[topic_id] = resp.body;
            this.$forceUpdate();
            this.$store.dispatch("stop_loading");
        }); 
      },
      getIndustries() {
        
        Vue.http.post(Config.config.base_host+'/topics_v1/search', {"topic_type": "industry"}).then(resp => {
         
          this.industries = resp.body;
          this.industries.rows = this.$_.sortBy(this.industries.rows, ["name"])

          this.getTopics(this.industries.rows[0]); 
          
        }); 

      },

      getTopics(industry) {
        this.selectedIndustry = industry;
        
        let params = {};
        
        if(this.project) {
          params = {"topic_type": "topic", "project_id": [this.project._id]};
        } else {
          params =  {"topic_type": "topic", "parent_id": [industry._id]};
        
        }

        params['limit'] = 100;
        
        Vue.http.post(Config.config.base_host+'/topics_v1/search', params).then(resp => {
         
          this.topics = resp.body.rows;
        }); 
      },

       getFollowTopic() {
      
        this.selectedIndustry = null;
       
        let params = {"topic_type": "topic", "bookmarked": true};
        let req = [];
        Vue.http.post(Config.config.base_host+'/topics_v1/search', params).then(resp => {
         
          resp.body.rows.forEach(it => {
            it.relations.news = null;
            it.relations.sections = null;
            req.push({"type_filter": Object.keys(this.filters).map((key) => {if(this.filters[key]) return key}).filter(x => x != null) ,"days": this.sortBy ,"q":[],"sortBy":"_score","project_ids":null,"topic_ids":[it._id], "page":1});
            /*Vue.http.post(`${Config.config.base_host}/topics_v1/news_feed`, {"type_filter": Object.keys(this.filters).map((key) => {if(this.filters[key]) return key}).filter(x => x != null) ,"days": this.sortBy ,"q":[],"sortBy":"_score","project_ids":null, "topic_ids":[it._id], "page":1}).then(res2 => {
              it.relations.news = res2.body;
            });*/

            Vue.http.post(`${Config.config.base_host}/topics_v1/sections`, {"type_filter": Object.keys(this.filters).map((key) => {if(this.filters[key]) return key}).filter(x => x != null) ,"days": this.sortBy ,"q":[],"sortBy":"_score","project_ids":null, "topic_ids":[it._id], "page":1}).then(res2 => {
              it.relations.sections = res2.body;
            });

          });

          this.topics = resp.body.rows;

          Vue.http.post(`${Config.config.base_host}/topics_v1/news_feed_bulk`, req).then(res2 => {

            res2.body.forEach(it => {
              this.topics.find(x => x._id == it.topic_id).relations.news = it.res;
            });
              
              
          });

        }); 
      }


  },
  components: {
      ProjectCard
  }
}

</script>
<style lang="scss">
  .desc { 
      em {
        font-style: normal;
        font-weight: bold !important;
        background-color: rgba(255, 255, 0, 0.226) !important;
      }
    }
</style>
<style lang="scss" scoped>
   
    .score {
      display: inline-block;
      font-size: 1em;
      padding: 1px;
      min-width: 100px;
      margin: 1px;
    }

    a {
        text-decoration: none !important;
    }
</style>