<template lang="pug">
  .filter-group(v-if="options")
    
    template(v-if="!loaded")
        v-select(placeholder="Loading ...", :disabled="true")

    div(v-show="loaded")
        v-select(:taggable="false", :multiple="multiple", placeholder="" v-model="value", :filterable="true", :reduce="it => it._id ", label="name", :options="options", :id="id")
            template(slot="no-options") Type to search
            template(slot="option" slot-scope="option")
                | {{option.name}} {{option.list_type}}
            
            
            template(slot="selected-option" slot-scope="option")
                .selected
                
                span(style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; max-width: 100px") {{ option.name }}
</template>

<script>
// @ is an alias to /src
import debounce from 'lodash/debounce';
import Config from '@/config'
import Vue from 'vue'
import Avatar from 'vue-avatar'
export default {
  name: 'WatchlistSelect',
  props: {
    "value":  {
      default: null
    },
    "id":  {
      default: null
    },
    "multiple":  {
      default: false
    },
    "item_type": {
      default: null
    }
  },
  data: function () {
    return {
        uid: null,
        options: [],
        loaded: false,
    }
  },

  mounted: function() {
     this.uid = this._uid;
      Vue.http.get(`${Config.config.base_host}/watchlists`).then(res => {
        if(this.item_type) {
          res.body = res.body.filter(x => x.item_type == this.item_type);
        }

        this.options = res.body;
        this.$nextTick(() => {
            this.loaded = true;
        });
        
      });

  },

  watch: {
    value: function(val) {
        this.$emit("input", val);
    }
  },
  
  methods: {
 
   onSearch(search, loading) {
      
      loading(true);
      this.search(loading, search, this);

    },

    search: debounce((loading, search, vm) => {
     loading(true);
      


     Vue.http.get(`${Config.config.base_host}/watchlists`).then(res => {
        if(vm.item_type) {
          res.body = res.body.filter(x => x.item_type == vm.item_type);
        }
        vm.options = res.body;
        vm.loaded = true;
      });
    }, 350)
  },

  components: {
    Avatar
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}

.disabled {
      pointer-events:none !important;
      color: #bfcbd9;
      cursor: not-allowed !important;;
      background-image: none;
      background-color: #eef1f6;
      border-color: #d1dbe5;   
    }

</style>
