
<template lang="pug">
    .main
      .top(v-if="internal_value && survey_template")
        .row.mb-2
            .col-8
                h6 {{survey_template.name}} </span>
            .col-4
                .float-right 
                    b-btn(variant="link", size="sm", v-if="editable && !edit_mode", @click="edit_mode=true").p-0.text-gray
                        i.fas.fa-pen.text-gray
        .row
            .col-12
                div(v-for="group in survey_template.groups")
                        b-card(no-body).mb-3
                          b-card-header()
                            | {{group.name}}
                          b-card-text().p-3 {{group.description}}
                            div(v-for="field in get_fields_by_group(group.name)")
                                b-form-group(:label-for="field.name", :label-cols-sm="4", label-cols-lg="3", :label="field.label", :description="field.description").fg.mb-5
                                    
                                    template(v-if="edit_mode")
                                       
                                        template(v-if="field.data_type == 'percent'")
                                          b-input-group(size="sm")
                                            //b-input(:id="field.name", type="number", min="0", max="100", v-model="internal_value.custom_data[field.name]", size="sm", autocomplete="off")
                                            VueSlider(:id="field.name", type="number", :min="0", :max="100",  v-model="internal_value.custom_data[field.name]", style="min-width: 200px").mr-1
                                            .small  {{internal_value.custom_data[field.name]}} %
                                            //b-input(:id="field.name", type="number", min="0", max="100", v-model="internal_value.custom_data[field.name]", size="sm", autocomplete="off")
                                        
                                        template(v-if="field.data_type == 'money_amount'")
                                          b-input-group(style="max-width: 200px", append="€", size="sm")
                                            b-input(:id="field.name", type="number", min="0", v-model="internal_value.custom_data[field.name]", size="sm", autocomplete="off")
                                           
                                        template(v-if="field.data_type == 'number'")
                                          b-input-group(style="max-width: 200px", size="sm")
                                            b-input(:id="field.name", type="number", min="0", v-model="internal_value.custom_data[field.name]", autocomplete="off")

                                        template(v-if="field.data_type == 'text'")
                                          b-input-group(style="max-width: 500px", size="sm")
                                           b-input(:id="field.name", type="text", v-model="internal_value.custom_data[field.name]", autocomplete="off")

                                        template(v-if="field.data_type == 'textarea'")
                                          b-input-group(style="max-width: 500px", size="sm")
                                            b-textarea(:id="field.name", type="text", v-model="internal_value.custom_data[field.name]", :rows="field.options.rows || 3", autocomplete="off")
                                         
                                        template(v-if="field.data_type=='select'")
                                          v-select(v-model="internal_value.custom_data[field.name]", :options="field.options").small

                                        template(v-if="field.data_type=='tags'")
                                          v-select(v-model="internal_value.custom_data[field.name]", :options="field.options", :multiple="true", :closeOnSelect="false").small

                                        template(v-if="field.data_type == 'slider'")
                                          b-input-group(size="sm")
                                            VueSlider(:id="field.name", type="number", :min="0", :max="10",  v-model="internal_value.custom_data[field.name]", style="min-width: 200px").mr-1
                                            .small  {{internal_value.custom_data[field.name]}}
                                          

                                    template(v-else)
                                        .non-edit
                                          template(v-if="Array.isArray(internal_value.custom_data[field.name])")
                                            b {{internal_value.custom_data[field.name].join(', ')}}
                                          template(v-else)
                                            b {{internal_value.custom_data[field.name]}}
                                          i(v-if="!internal_value.custom_data[field.name]") Not answered yet
        div(v-if="edit_mode").mb-5
          .row.mt-2
              .col
                  .d-flex.align-items-end()
                      b-btn(variant="outline-secondary",@click="edit_mode=false").ml-auto Cancel
                      b-btn(variant="outline-primary", @click="save_survey()") Send answers
</template>

<script>

// @ is an alias to /src
//import Config from '@/config'
import Vue from 'vue';
import Config from '@/config'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'


export default {
  name: 'Survey',
  props: {
    "value":  {
      default: {"custom_data": {}}
    },
    "survey_template": {
        default: null
    },
    "edit_mode":  {
      default: false
    },
    "editable":  {
      default: false
    },
    "link_token":  {
      default: null
    },
    "inline":  {
      default: true
    },
    "user":  {
      default: null
    },
 
  },

  watch: {

    value: {
      deep: true,
      handler(val) {
        this.internal_value = val;
      }
    },

    internal_value: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      }
    }
  },


  data: function () {
    return {
        internal_value: null,       
    }
  },

  mounted: function() {
     this.uid = this._uid;
     this.internal_value = this.value;
  },

  methods: {
      
      get_fields_by_group(name) {
          return this.survey_template.data_fields.filter(x => x.group == name);
      },

      save_survey() {
        if(this.user) {
            this.internal_value.owner_id = this.user._id;
        }
        this.internal_value.survey_template_id = this.survey_template._id;


        Object.keys(this.internal_value.custom_data).forEach(key => {
          if(['number', 'percent', 'money_amount'].includes(this.survey_template.data_fields.filter(x => key == x.name)[0].data_type)) {
            this.internal_value.custom_data[key] = parseFloat(this.internal_value.custom_data[key]);
          }
          
        });

        if(!this.internal_value._id) {
            
            this.$store.dispatch("start_loading");    
           
           
         
           Vue.http.post(`${Config.config.base_host}/surveys`, this.internal_value).then(res => {
                this.internal_value = res.body;
                this.$store.dispatch("stop_loading");
                this.$emit("saved", this.internal_value);
                this.edit_mode = false;
                this.$bvToast.toast("Survey saved", {
                        title: 'Saved',
                        variant: 'outline-primary',
                        autoHideDelay: 1000,
                });

            });

        } else {

            let url = `${Config.config.base_host}/surveys/${this.internal_value._id}`;
           
           if(this.link_token) {
             url = `${Config.config.base_host}/surveys/by_token/${this.link_token}`
           }

            this.$store.dispatch("start_loading");    
            Vue.http.put(url, this.internal_value).then(res => {
                this.internal_value = res.body;
                this.$store.dispatch("stop_loading");
                this.$emit("saved", this.internal_value);
                if(this.link_token) {
                  location.href = "https://ecosystem-manager.co"
                }
                this.edit_mode = false;
                this.$bvToast.toast("Survey saved", {
                        title: 'Saved',
                        variant: 'outline-primary',
                        autoHideDelay: 1000,
                });
            });
        }

              
      },
      

  },

  components: {
    VueSlider
  }
}

</script>

<style lang="scss">

.fg {
  .non-edit {
    
  }
  .col-form-label {
    font-weight: bold;
  }
  .text-muted {
    opacity: 0.6;
  }


}





</style>
