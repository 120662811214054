<template lang="pug">
  .project-modal
    b-modal(id="project-modal", :title="title", size="lg", ok-variant="outline-primary", cancel-variant="link", ref="project-ref",  @ok="save()")
      b-form(size="sm", autocomplete="off")
        b-tabs(content-class="mt-3")
          b-tab(title="Main" active)
            .form-row()
            
              .col-12
                b-form-group(id="name-group"  
                            label="Name"
                            size="sm"
                            label-for="name"
                            description="",
                            v-bind:class="{ 'form-group--error': $v.form.name.$error }")

                        b-form-input(id="name"
                                    type="text"
                                    :state="!submitStatus ? null : !$v.form.name.$invalid"
                                    size="sm"
                                    placeholder="", v-model="form.name")
              
              .col-12
                b-form-group(id="description-group"
                            label="Description"
                            label-for="exampleInput1",
                      
                            description="")
                        b-textarea(id="description"
                                    type="text"
                                    size="sm"
                                    placeholder="", @change="", v-model="form.description")
              
              .col-12
                b-form-group(id="stages-group"
                              label="Keywords"
                              label-for="exampleInput1"
                              description="").mt-2
                          
                  TopicKeywordSelect(id="keyword-select", v-model="form.keywords", :show-recommendations="false") 

              
                //b-tab(title="Social / Profiles", size="sm", id="tab-profiles")
                
                  .row.mt-3
                    div().col-6

                            b-form-group(id="exampleInputGroup1"
                                  label="LinkedIn"
                                  size="sm"
                                  label-for="linkedin_url"
                                  description="")
                              
                              b-form-input(id="linkedin_url"
                                          type="text"
                                          size="sm",  v-model="form.linkedin_url")
                                          
                            b-form-group(id="exampleInputGroup1"
                                    label="Crunchbase"
                                    label-for="crunchbase_url"
                                    description="")

                                b-form-input(id="crunchbase_url"
                                            type="text"
                                            size="sm", v-model="form.crunchbase_url")
                              
                            b-form-group(id="exampleInputGroup1"
                                    label="Pitchbook"
                                    size="sm"
                                    label-for="pitchbook_url"
                                    description="")
                                b-form-input(id="pitchbook_url"
                                            type="text"
                                            size="sm", v-model="form.pitchbook_url")
                                
                    div().col-6

                            b-form-group(id="exampleInputGroup1"
                                    label="Twitter"
                                    label-for="twitter_url"
                                    description="")

                                b-form-input(id="twitter_url"
                                            type="text"
                                            size="sm", v-model="form.twitter_url")


                            b-form-group(id="exampleInputGroup1"
                                  label="Facebook"
                                  label-for="facebook_url"
                                  description="")

                              b-form-input(id="facebook_url"
                                          type="text"
                                          size="sm", v-model="form.facebook_url")
                          
                            
                            b-form-group(id="exampleInputGroup1"
                                    label="AngelList"
                                    size="sm"
                                    label-for="angel_url"
                                    description="")
                                b-form-input(id="angel_url"
                                            type="text"
                                            size="sm", v-model="form.angel_url")

        
      template(slot="modal-footer")
            
            //b-btn(variant="outline-secondary" @click="$refs.modal1.hide(false)") Close
            b-btn(variant="primary"  @click="save()")#project-modal-save {{ok_btn_text}}



</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'

import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import UserMultiSelect from '@/components/UserMultiSelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'
import Config from '@/config'
import Vue from 'vue'

export default {

  name: 'CompanyModal',
  props: [],
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        }
     
    }
  },

  data: function () {
    return {
        
        uid: null,
        title: "Add a new topic",
        form: {"description": "", "name": "", member_ids: [], owner_id: null, stages: ["Watch", "Review", "Closed", "Rejected"], status: 'Ongoing'},
        options: [],
        submitStatus: null,
        ok_btn_text: "Add Topic"
        
    }
  },

  mounted: function() {
     this.uid = this._uid;
  },

  methods: {

    open(project) {
    
      if(project) {
        this.form = JSON.parse(JSON.stringify(project));
        this.ok_btn_text = "Save";
        this.title = "Edit " + project.name;
        this.form.project_id = this.$route.params.id
      } else  {
        this.form = {"description": "", "name": "", keywords: [], "project_id": this.$route.params.id};
      }
      
      this.$refs['project-ref'].show()
    },
    
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        this.submitStatus = 'PENDING'
        console.log(this.form);
        Vue.http.post(`${Config.config.base_host}/topics_v1`, this.form).then((res) => {
          console.log(res)
          location.href="/projects/" + this.$route.params.id + '/topics';
        });
        
      }
      

    },

    
  },

  components: {
    CountrySelect,
    UserMultiSelect,
    TopicKeywordSelect
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
