<template lang="pug">
  .chart-holder.bar-chart
    CommonChart( :chartData="chart_data", :chartOptions="chart_options", :chartType="chartType")

</template>

<script>
import CommonChart from "./CommonChart";
export default {
  name: "BarChartStacked",
  props:{
		chart_data: Object,
    chart_options: Object
	},
	data() {
		return {
			chartType: "bar"
		};
	},
	components: {
    CommonChart
	}
};


			// chartData: {
      //   labels: ["Jan1", "Jan2", "Jan3", "Jan4", "Jan5", "Jan6", "Jan7"],
      //   datasets: [
      //     {
      //       label: "Data Set 1",
      //       data:  [30, 100, 10, 40, 39, 80, 40],
      //       backgroundColor: "#5cddff",
      //       hoverBackgroundColor: " #5cddff"
      //     },
      //     {
      //       label: "Data Set 2",
      //       data:  [20, 10, 12, 33, 22, 4, 0],
      //       backgroundColor: "#dddddd",
      //       hoverBackgroundColor: "#dddddd"
      //     },
      //     {
      //       label: "Data Set 3",
      //       data:  [25, 20, 32, 33, 12, 24, 10],
      //       backgroundColor: "red",
      //       hoverBackgroundColor: "red"
      //     }
      //   ]
      // },
</script>

<style></style>