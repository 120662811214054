<template lang="pug">
	.chart-scaffold
		canvas.base-chart( :id="uniqueID" v-bind:class="uniqueID" )
</template>

<script>
import Chart from "chart.js";

export default {
  name:"CommonChart",
	props:{
		chartType: 	String,
		chartData: 	Object,
		chartOptions: Object
	},
  data: function() {
    return {
      uniqueID: "canvas-" + Date.now() + "u" + this.$_.uniqueId()
    }
  },
  watch: {
    $props: {
      handler() {
        this.initiateComponent();
      },
      deep: true
    }
  },
	methods: {
		initiateComponent(){
			let {chartType,chartData,chartOptions} = this;
			
			this.chartConstructor(chartType, chartData, chartOptions);
		
		},
		chartConstructor(chartType, chartData, chartOptions) {
			
		const chartElement = document.querySelector(`.base-chart.${this.uniqueID}`);
		
		console.log(chartElement, chartType, chartData);

		window.chart = window.chart || {};
		
		if( window.chart[ this.uniqueID ] ){
			window.chart[ this.uniqueID ].destroy();
		}

		window.chart[ this.uniqueID ] = new Chart(chartElement, {
			type: chartType,
			data: chartData,
			options: chartOptions,
		});
			
		
		},
	},
	
	mounted(){
		this.initiateComponent();
	}
};
</script>

<style scoped lang="scss">
  .base-chart{
    min-height: 300px;
  }
</style>
