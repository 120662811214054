<template lang="pug">
    
    .home.mt-2(v-if="selectedTopic")
        .row
            .col-3

              div(style="max-width: 400px")
                b {{selectedTopic.custom_data['short_description']}}
              div(v-if="selectedTopic && selectedTopic.custom_data")
                div(v-if="selectedTopic.custom_data['industry'] && selectedTopic.custom_data['industry'].length > 0")
                  label.mt-3 Industries
                  TaxonomyDisplay(topic_type="industry", :topic_ids="selectedTopic.custom_data['industry']")
              
              div(v-if="selectedTopic && selectedTopic.custom_data")
                div(v-if="selectedTopic.custom_data['enterprise_function'] && selectedTopic.custom_data['enterprise_function'].length > 0")
                  label.mt-3 Enterprise Functions
                  TaxonomyDisplay(topic_type="enterprise_function", :topic_ids="selectedTopic.custom_data['enterprise_function']")

              div(v-if="selectedTopic && selectedTopic.custom_data")
                div(v-if="selectedTopic.custom_data['vertical'] && selectedTopic.custom_data['vertical'].length > 0")
                  label.mt-3 Verticals
                  TaxonomyDisplay(topic_type="vertical", :topic_ids="selectedTopic.custom_data['vertical']")
              
              div(v-if="selectedTopic && selectedTopic.custom_data")
                div(v-if="selectedTopic.custom_data['sub_vertical'] && selectedTopic.custom_data['sub_vertical'].length > 0")
                  label.mt-3 Sub-Verticals
                  TaxonomyDisplay(topic_type="sub_vertical", :topic_ids="selectedTopic.custom_data['sub_vertical']")

              div(v-if="selectedTopic && selectedTopic.custom_data")
                div(v-if="selectedTopic.custom_data['problem']")
                  label.mt-3 Problem
                  .text-muted.small {{selectedTopic.custom_data['problem']}}

                div(v-if="selectedTopic.custom_data['solution']")
                  label.mt-3 Solution
                  .text-muted.small {{selectedTopic.custom_data['solution']}}

                div(v-if="selectedTopic.custom_data['key_benefits']")
                  label.mt-3 Key benefits
                  .text-muted.small {{selectedTopic.custom_data['key_benefits']}}
                
                div(v-if="selectedTopic.custom_data['url_credentials']")
                  label.mt-3 Credentials
                  .text-muted.small(v-if="selectedTopic.custom_data['url_credentials'].length > 5") 
                    b-link(target="_blank", :href="selectedTopic.custom_data['url_credentials']") {{selectedTopic.custom_data['url_credentials']}}
                  .text-muted.small(v-else)
                    div(v-for="cred in selectedTopic.custom_data['url_credentials']") 
                      b-link(target="_blank", :href="cred") {{cred}}



            .col-4
              b-card(title="Projects" no-body, style="max-width: 400px")
                
                .p-3
                  b() Used in projects
              
                .text-muted.p-3(v-if="!selectedTopic.index_space['projects'] || selectedTopic.index_space['projects'].length == 0") There are no companies mapped yet.
                b-list-group(style="max-height: 500px; overflow-y: auto" )
                  b-list-group-item(v-for="project in selectedTopic.index_space['projects']" :href="'/projects/'+project._id+'/dashboard'", @click="select_org(project)") 
                    .media
                      .media-body 
                        .form-row
                          .col-2
                            Logo(:name="project.name", :size="40", :inline="true", :rounded="false")
                          .col-8
                            | {{project.name}}
                            .small.text-muted {{project.group}}

            .col-5
              b-card(title="Companies" no-body)
                .p-3
                  .row
                    .col-8
                      b() Companies for {{selectedTopic.name}}
                   
                .text-muted.p-3(v-if="organizations != null && organizations.length == 0") This use case is not used in any project yet.
                b-list-group(style="max-height: 500px; overflow-y: auto" )
                  b-list-group-item(v-for="org in organizations" button, @click="select_org(org)") 
                    .media
                      div(class="feed-image-small" :style="'background: url(https://images.hytechnologies.co/unsafe/50x50/logo.clearbit.com/' + org.web_entity + ') center 0% / cover no-repeat;'").mr-3
                      .media-body 
                        | {{org.name}}
                        //.mt-1
                        //label.mr-1 Projects:  
                        //b-badge(v-for="project in org.index_space.projects").mr-1 {{project.name}} <span v-if="project.groups && project.groups.length > 0 && project.groups[0]"> &gt; {{project.groups[0]}}</span>
</template>

<script>

import Config from '@/config'
import Vue from 'vue'
import UseCaseCard from '@/components/UseCaseCard.vue'
import TaxonomyDisplay from '@/components/TaxonomyDisplay.vue'
import Logo from '@/components/Logo.vue'
import { mapFields } from 'vuex-map-fields';

import TopicModal from '@/components/TopicModal.vue'
export default {
  
  name: 'TopicOverview',
  
  data: function () {
      return {
        topic: null,
        projects: null,
        organizations: null,
        selected_topic: "",
  
      }
  },

 computed: {
      ...mapFields([
        'sidebar_project.selected_item',
        'sidebar_project.selected_list',
        'selectedTopic'
      ]),

  },

  mounted: function() {
    
    this.$store.state.sidebar_project.selected_item = null;
    this.$store.state.sidebar.selectedUseCase = this.$route.params.topid_id;
    this.host = Config.config.base_host;
    this.getData();
  },


  methods: {

    getProjects(topic_ids) { 
      this.organizations = null;
      
      Vue.http.post(`${Config.config.base_host}/projects/search`, {"filter": {topic_id: topic_ids}}).then(res => {
        this.projects = res.body.rows;
        this.$forceUpdate();
        this.$store.dispatch("stop_loading");
      });
    },


     getOrganizations(topic_ids) { 
      this.organizations = null;
      
      Vue.http.post(`${Config.config.base_host}/organizations/search`, {"filter": {topic_id: topic_ids, "sources": ["ecosystem"]}}).then(res => {
        this.organizations = res.body.rows;
        this.$forceUpdate();
        this.$store.dispatch("stop_loading");
      });
    },

    getData() { 
      this.$store.dispatch("start_loading");
      Vue.http.get(`${Config.config.base_host}/topics_v1/${escape(this.$route.params.topic_id)}`).then(res => {
        this.selectedTopic = res.body;
        this.getOrganizations([this.selectedTopic._id]);
        this.$store.dispatch("stop_loading");
      });
    },

     select_org(org) {
    
      this.$store.state.sidebar_project.selected_item = org;
      this.selected_org = org;
      this.$forceUpdate();

    },


    showModal(topic) {
    
        this.$refs.topicModal.open(topic);
    },
  },

  components: {
      UseCaseCard,
      TopicModal,
      TaxonomyDisplay,
      Logo
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
</style>