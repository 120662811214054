<template lang="pug">
  .topic2-modal
    b-modal(id="topic2-modal", :title="title", size="lg", ok-variant="outline-primary", cancel-variant="link", ref="usecaseref",  @ok="save()")
      b-form(size="sm", autocomplete="off")
        
        b-card 

          .form-row()
           
            .col-6
              b-form-group(id="name-group"  
                          label="Name*"
                          size="sm"
                          label-for="name"
                          description="",
                          v-bind:class="{ 'form-group--error': $v.form.name.$error }")

                      b-form-input(id="name"
                                  type="text"
                                  maxlength="40"
                                  :state="!submitStatus ? null : !$v.form.name.$invalid"
                                  size="sm"
                                  placeholder="", v-model="form.name")
            
            .col-6
              b-form-group(id="name-group"  
                          label="Cluster"
                          size="sm"
                          label-for="group"
                          description="",
                          maxlength="40"
                        )
                      
                      v-select(id="group"
                                 
                                  :options="use_case_cluster"
                                  :taggable="false"
                                  :select-on-tab="true"
                                  v-model="form.group").small
                        template(#no-options="{ search, searching, loading }") Select or enter a new cluster name and press enter.
        
        .form-row().mt-2
          .col-12
            b-card 
                //b Select domain
                .form-row
                
                  template(v-for="field in project_config")
                      b-form-group(v-if="field.topic_type != 'sub_vertical' || (form.custom_data['vertical'] && form.custom_data['vertical'].indexOf('db8c995d6b52770ef61bc39821d3a3d5') > -1)", id="org_tags-group", :label="field.name", label-for="org_tags", label-size="sm", ).col-6
                        TaxonomySelect(id="stage"
                                    
                                    :topic_type="field.topic_type",
                                    :multiple="true"
                                    :select-on-tab="true"
                                    v-model="form.custom_data[field.topic_type]").small

                .col-12
                  div(v-if="min_domain_error").alert.alert-danger Please choose at least one industry or one enterprise function

        .form-row().mt-2
          .col-12
            b-card 
              //b.mt-3 Use case description
              .form-row
                .col-6
                  b-form-group(id="short_description-group"
                          label="Short description"
                          label-for="short_description",
                          description="")
                      b-textarea(id="short_description"
                                  type="text"
                                  size="sm"
                                  :rows="3"
                                  required
                                  maxlength="400"
                                  placeholder="Short description", @change="", v-model="form.custom_data['short_description']")

                .col-6
                  b-form-group(id="problem-group"
                              label="Problem"
                              label-for="problem",
                              description="")
                          b-textarea(id="problem"
                                      type="text"
                                      size="sm"
                                      :rows="3"
                                      required
                                      maxlength="400"
                                      placeholder="Describe the Problem", @change="", v-model="form.custom_data['problem']")
                .col-6
                  b-form-group(id="solution-group"
                              label="Solution"
                              label-for="solution",
                        
                              description="")
                          b-textarea(id="solution"
                                      type="text"
                                      size="sm"
                                      :rows="3"
                                      maxlength="400"
                                      placeholder="Describe the solution", @change="", v-model="form.custom_data['solution']")
                .col-6
                  b-form-group(id="benefits-group"
                              label="Key benefits"
                              label-for="benefits",
                        
                              description="")
                          b-textarea(id="benefits"
                                      type="text"
                                      size="sm"
                                      :rows="3"
                                      maxlength="400"
                                      placeholder="What are the key benefits of the solution for the user?", @change="", v-model="form.custom_data['key_benefits']")
                .col-12
                  b-form-group(id="url_credentials-group"
                            label="Credentials"
                            label-for="url_credentials",
                      
                            description="")
                        v-select(id="url_credentials"
                                    type="text"
                                    size="sm"
                                    :multiple="true"
                                    :taggable="true"
                                    placeholder="URL credentials", v-model="form.custom_data['url_credentials']")
                          template(#no-options="{ search, searching, loading }") Enter an URL crednetial and press enter
      

      template(slot="modal-footer")
            
            b-btn(variant="outline-secondary" @click="$refs.usecaseref.hide(false)") Close
            b-btn(variant="outline-primary"  @click="save()")#project-modal-save {{ok_btn_text}}



</template>

<script>
// @ is an alias to /src
import { validationMixin } from 'vuelidate'

import { required, minLength } from 'vuelidate/lib/validators'
import CountrySelect from '@/components/CountrySelect'
import UserMultiSelect from '@/components/UserMultiSelect'
import TopicKeywordSelect from '@/components/TopicKeywordSelect'
import TaxonomySelect from '@/components/TaxonomySelect'
import Config from '@/config'
import Vue from 'vue'

export default {

  name: 'UseCaseModal',
  props: [],
  
  mixins: [
    validationMixin
  ],
  validations: {
    form: {
        name: {
            required,
            minLength: minLength(1)
        }
    }
  },

  data: function () {
    return {
        custom_data: {"url_credentials": [], industry: [],enterprise_function: [], vertical: [], sub_vertical: []},
        project: null,
        pt: null,
        usecase: null,
        min_domain_error: false,
        project_config: [{"name": "Industry", "topic_type": "industry"},{"name": "Enterprise Function", "topic_type": "enterprise_function"},{"name": "Vertical",  "topic_type": "vertical"}, {"name": "Sub-Vertical",  "topic_type": "sub_vertical"}],
        uid: null,
        title: "Create Use Case",
        form: {"group": null, "custom_data": {"url_credentials": [], "industry": [], "enterprise_function": [], "vertical": [], "sub_vertical": [], "use_case_cluster": "", "key_benefits": "", "solution": "", "problem": ""}, "description": "", "name": "", "cluster": null, owner_id: null},
        options: [],
        submitStatus: null,
        use_case_cluster: [],
        ok_btn_text: "Add Use Case"
    }
  },

  mounted: function() {
     this.uid = this._uid;
  },

  methods: {
    changed(ev) {
      alert(ev)
    },

    open(pt, usecase, use_case_cluster, project = null) {
      this.min_domain_error = false;
      this.use_case_cluster = use_case_cluster;
      this.pt = pt;
      if(usecase) {
        usecase.group = pt.group;
        this.form = JSON.parse(JSON.stringify(usecase));
        
        this.ok_btn_text = "Save";
        this.title = "Edit " + usecase.name;
        
      } else  {
        this.form = {"topic_type": "use_case", "group": null,"custom_data": {"url_credentials": [], "industry": null, "enterprise_function": null, "vertical": null, "sub_vertical": null, "use_case_cluster": "", "key_benefits": "", "solution": "", "problem": ""}, "description": "", "name": "", owner_id: null};
        this.form.industry = []
        if(project) {
          console.log(project);
          this.form.custom_data.industry = project.custom_data['industry'] || [];
          this.form.custom_data.enterprise_function = project.custom_data['enterprise_function'] || [];
          this.form.custom_data.vertical = project.custom_data['vertical'] || [];
        }
        
        
      }
      this.form.project_id = pt.project_id;
      this.form.group = pt.group;

      this.$refs['usecaseref'].show()
    },
    
    save() {
      this.$v.$touch()
     
      if(!this.form.custom_data['industry']) this.form.custom_data['industry'] = [];
      if(!this.form.custom_data['enterprise_function']) this.form.custom_data['enterprise_function'] = [];

      if (this.$v.$invalid || (this.form.custom_data['industry'].length + this.form.custom_data['enterprise_function'].length) == 0) {
        if((this.form.custom_data['industry'].length + this.form.custom_data['enterprise_function'].length) == 0) {
          this.min_domain_error = true;
        }
        this.submitStatus = 'ERROR';
 
      } else {
        this.submitStatus = 'PENDING'
        console.log(this.form);
        if(!this.pt._id) {
          Vue.http.post(`${Config.config.base_host}/topics_v1`, this.form).then((res) => {
            console.log(res)
            location.reload();
          });
        } else {
          this.form.topic_id = this.pt.topic_id;
          Vue.http.put(`${Config.config.base_host}/topics_v1/${this.form.topic_id}`, this.form).then((res) => {
            console.log(res)
            location.reload();
          });
        }
        
      }
      
    },

    
  },

  components: {
    CountrySelect,
    UserMultiSelect,
    TopicKeywordSelect,
    TaxonomySelect
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}
</style>
