<template lang="pug">
  .container-xxl
    .topic-modal(  v-if="!$route.params.topic_id || ($route.params.topic_id && selectedTopic)" )
      Topic2Modal( ref="topicModal" :keywords="keywords")
    .row( v-if="!$route.params.topic_id || ($route.params.topic_id && selectedTopic)" )
      .col-12.col-lg-10.offset-lg-1
        .outer-container.pb-4.mx-4
          .pt-4.pb-2.h4.topic-heading 
          //Keywords
          .clearfix
            .float-right(v-if="!selectedTopic.relations.curator")
              b-btn(size="sm", variant="outline-primary", @click="showModal( selectedTopic )")#project-add-edit Save topic
            .keywords-holder( v-if="selectedTopic && selectedTopic.full_query" )
              .text-muted(v-if="selectedTopic.relations.curator")
                | Query: "{{selectedTopic.full_query.join(" ")}}"
              div(v-else)
                TopicKeywordSelect(id="keyword-select", v-model="selectedTopic.full_query", @search="fetchDatatoVisualise()") 
              
        .search-container.mx-4( v-if="analyzingData" )
          .text-center.text-small.text-muted.p-4 Analyzing market ...
          
        .inner-container.mx-4
          .line-chart-holder.topic-chart-holder.mt-5.ibm( v-if="mediaAttentionData && mediaAttentionData.datasets" )
            .text-center.pt-4.topic-heading Media attention trend last year
            .clearfix.px-4.pb-4
              LineChartNew( :chart_data="mediaAttentionData", :chart_options="lineChartOptions")  
          .tables-container.topic-chart-holder.mt-5.ibm( v-if="rankingTableData && rankingTableData.items" )
            .text-center.pt-4.pb-2.clearfix
              .topic-heading.ibm Selected companies
              .float-right.mr-4
                b-link.small( :to="'/ecosystem/list?full_query=' + this.selectedTopic.full_query + ''") Open companies
            .clearfix.p-1.px-4
              b-table.topic-table.h6( 
                    :striped="true",
                    :bordered="false",
                    :small="true",
                    :no-border-collapse="true",
                    :items="rankingTableData.items",
                    :fields="rankingTableData.fields",
                    :head-variant="''",
                    :table-variant="''",
                    :sticky-header="true",
                    head-variant="light",
                    sort-icon-left
                  )
                template(v-slot:cell(company)="row")
                  .company-name.text-left.media
                    .feed-image-small.mr-2( :style="'background: url(https://images.hytechnologies.co/unsafe/30x30/logo.clearbit.com/' + row.item.company.web_entity + ') center 0% / cover no-repeat;'")
                    .media-body.text-left
                      b-link(:to="'/ecosystem/orgs/'+row.item.company['_id']") {{row.item.company.name}} 
                template(v-slot:cell(total_funding)="row")
                 .total_funding.text-right {{ row.item.total_funding ? '$' + row.item.total_funding.toString().formatPriceMillion() : "N.A." }}
                template(v-slot:cell(last_funding_on)="row")
                 .last_funding_on.text-right {{ row.item.last_funding_on | moment("DD.MM.YYYY") }}
                template(v-slot:cell(founding_date)="row")
                 .founding_date.text-right {{ row.item.founding_date | moment("DD.MM.YYYY") }}

          .line-chart-holder.topic-chart-holder.mt-5.ibm( v-if="totalFundingData && totalFundingData.datasets" )
            .text-center.pt-4.topic-heading Funding activity
            .clearfix.px-4.pb-4
              BarChartStacked( :chart_data="totalFundingData" :chart_options="mixedChartOptions")  
          .tables-container.topic-chart-holder.mt-5.ibm( v-if="investorRankingData && investorRankingData.items" )
            .text-center.pt-4.topic-heading.pb-2.clearfix
              .topic-heading.ibm Top investors in topic
              .float-right.mr-4
                //b-link.small( :to="'/ecosystem/list?keywords=' + keywords.join(',')" ) See all
            .clearfix.p-1.px-4
              b-table.topic-table.h6( 
                    :striped="true",
                    :bordered="false",
                    :small="true",
                    :no-border-collapse="true",
                    :items="investorRankingData.items",
                    :fields="investorRankingData.fields",
                    :head-variant="''",
                    :table-variant="''",
                    :sticky-header="true",
                    head-variant="light",
                    sort-icon-left
                  )
                template(v-slot:cell(company)="row")
                  .company-name.text-left.media
                    .feed-image-small.mr-2( :style="'background: url(https://images.hytechnologies.co/unsafe/30x30/logo.clearbit.com/' + row.item.company.web_entity + ') center 0% / cover no-repeat;'")
                    .media-body.text-left 
                      b-link( v-if="row.item.company['_id']",  :to="'/ecosystem/orgs/'+row.item.company['_id']") {{row.item.company.name}} 
                      .company-name-text( v-else ) {{row.item.company.name}} 
                 
              
                template(v-slot:cell(invested_in_topic)="row")
                 .invested_in_topic.text-right {{'$' + row.item.invested_in_topic.toString().formatPriceMillion()}}
                template(v-slot:cell(total_investment)="row")
                 .total_investment.text-right {{'$' + row.item.total_investment.toString().formatPriceMillion()}}
                template(v-slot:cell(last_invested)="row")
                 .last-invested.text-right {{row.item.last_invested | moment("DD.MM.YYYY")}}
          
          .line-chart-holder.topic-chart-holder.mt-5.ibm( v-if="dealShareData && dealShareData.datasets" )
            .text-center.pt-4.topic-heading Investments by stage
            .clearfix.px-4.pb-4
              BarChartStacked( :chart_data="dealShareData" :chart_options="stackedChartOptions")
              
          //.line-chart-holder.topic-chart-holder.mt-5.ibm( v-if="foundingsData && foundingsData.datasets" )
            .text-center.pt-4.topic-heading Foundings
            .clearfix.px-4.pb-4
              BarChartStacked( :chart_data="foundingsData" :chart_options="stackedChartOptions")  

          .line-chart-holder.topic-chart-holder.mt-5.ibm(v-if="$route.params.topic_id && selectedTopic")
            .text-center.pt-4.topic-heading Projects
            .clearfix.px-4.pb-4
                ProjectStageSelect(id="projectStageAdd"  v-model="selectedProjectStage", :create_option="false", placeholder="Select project").small
                .mt-1
                  b-btn(variant="outline-primary", @click="add_to_project(selectedProjectStage, [selectedTopic])", size="sm", :disabled="!selectedProjectStage").form-control Add to project
                .mt-3
                  b-card(v-for="pt in this.$_.sortBy(selectedTopic.index_space.projects, ['name'])").mt-1
                    .media
                      Logo(:name="pt.name", :size="40", :inline="true", :rounded="false").mr-1
                      .media-body 
                        b-link(:to="'/projects/'+ pt._id.split('::')[0]")
                          | {{pt.name}}
                        .small.text-muted {{pt.stage && pt.stage != "_deleted_" ? pt.stage : "Not assigned"}}   
                          b 
                            b-link(:to="'/topics/usecases/'+ pt._id.split('::')[1]") {{pt.topic_name}}   
                                        
   
  
</template>

<script>


import Config from '@/config';
import Vue from 'vue';
import { mapFields } from 'vuex-map-fields';
import Logo from '@/components/Logo';
import BarChartStacked from "@/components/BarChartStacked";
import LineChartNew from "@/components/LineChartNew";
import ProjectStageSelect from '@/components/ProjectStageSelect';
import TaxonomyTagger from '@/components/TaxonomyTagger';
import Topic2Modal from '@/components/Topic2Modal';
import TopicKeywordSelect from '@/components/TopicKeywordSelect';

export default {
  
  name: 'TopicOverview',

  data: function () {
          return {
              bar_chart_colors: ["#7496b2","#516e81","#aac0d1","#7a6ecc","#4ac596","#26b4d2","#f76664"],
              line_chart_colors: ["#4ac596","#f7d864","#26b4d2","#f76664"],
              rankingTableData: {},
              keywords: [],
              investorRankingData: {},
              analyzingData: false,
              mediaAttentionData: {
                labels: null,
                datasets: null
              },
              dealShareData: {
                labels: null,
                datasets: null
              },
              totalFundingData: {
                labels: null,
                datasets: null
              },
              foundingsData: {
                labels: null,
                datasets: null
              },
              stackedChartOptions: {
                responsive: true,
                scales: {
                  xAxes: [
                    {
                    
                     
                      stacked: true,
                      gridLines: { 
                        display: false,
                      },
                    }
                  ],
                  yAxes: [
                    {
                      ticks: {
                        min: 0,
                        beginAtZero: true
                        
                      },
                    
                      stacked: true,
                      gridLines: { 
                        display: true,
                        color:"#ffffff"
                      },
                    }
                  ]
                },
              },
              mixedChartOptions: {
                responsive: true,
                scales: {
                  xAxes: [
                    {

                      
                      stacked: false,
                      gridLines: { 
                        display: false,
                      },
                    }
                  ],
                  yAxes: [
                      {
                      ticks: {
                        min: 0,
                        beginAtZero: true
                        
                      },
                      min: 0,
                      id: 'Fundings',
                      type: 'linear',
                      position: 'left',
                      stacked: false,
                      gridLines: { 
                        display: true,
                        color:"#ffffff"
                      },
                    },
                    {
                      ticks: {
                        min: 0,
                        beginAtZero: true
                        
                      },
                      min: 0,
                      id: 'Raised',
                      type: 'linear',
                      position: 'right',
                      stacked: false,
                      gridLines: { 
                        display: false
                      },
                    }
                  ]
                },
              },
              lineChartOptions: {
                scales: {
                  xAxes: [
                    {
                     
                      gridLines: { 
                        display: true,
                        color:"#ffffff"
                      },
                    },
                  ],
                  yAxes: [
                    {
                      min: 0,
                      beginAtZero: true,
                      gridLines:{
                        color:"#ffffff"
                      },
                      ticks: {
                        min: 0,
                        beginAtZero: true,
                        stepSize: 0.01,
                      },
                    },
                  ],
                },
                maintainAspectRatio: false,
                legend: {
                  display: false
                },
                tooltips: {
                  callbacks: {
                    label: function(tooltipItem) {
                      return tooltipItem.yLabel;
                    }
                  }
                },
                animation: {
                  duration: 2000,
                  easing: "easeInOutQuart",
                },
              
              },
          };
        },
  mounted: function() {
    if(this.$route.path=="/topics/add_topic") this.selectedTopic = {full_query: [], "relations": {}, "advanced_query": [{keyword: "", include_kw: [], exclude_kw: []}]};
    this.initializeComponent();
  },
  watch: {

    '$route.params.topic_id': function () {
      if(this.$route.params.topic_id) {
        this.$store.dispatch("loadTopic", this.$route.params.topic_id);
      }
      
      window.dispatchEvent(new Event('resize'));
    },

    'selectedTopic._id': function () {
     
      this.fetchDatatoVisualise();
    },


  
  },
  computed: {
      ...mapFields([
        'selectedTopic',
        'sidebar.selectedProjectStage'
      ]),

  },
  methods: {
    initializeComponent(){
    
      if( !this.selectedTopic  ){
     
        this.selectedTopic = {full_query: [],  "relations": {}, "advanced_query": [{keyword: "", include_kw: [], exclude_kw: []}]};
     
      }

      this.fetchDatatoVisualise();

    },
    fetchDatatoVisualise(){
      this.mediaAttentionData = null;
      if( ( this.selectedTopic && this.selectedTopic.full_query && this.selectedTopic.full_query.length > 0 ) ){
        this.setRankingTableData();
        this.setInvestorRankingTableData();
        this.setMediaChartData();
        this.setFundingIndicators();
        this.setFoundingsDataIndicators();
        this.setDealShareData();
      }
      else{
        this.rankingTableData     = {};
        this.investorRankingData  = {},
        this.mediaAttentionData   = this.foundingsData = this.dealShareData = this.totalFundingData = {
                                      labels: null,
                                      datasets: null
                                    };
      }
    },
    showModal( topic ) {
        this.$refs.topicModal.open( topic );
    },
    setRankingTableData(){
      let dataToPost = {
                          //advanced_query: this.selectedTopic.advanced_query,
                          
                          full_query: this.selectedTopic.full_query,
                          sortBy: "last_funding_on",
                          sources: ["hy"]
                        };
      
      Vue.http.post(`${Config.config.base_host}/organizations/search`, {"filter": dataToPost } ).then((res) => {
        this.rankingTableData        = {}; 
        this.rankingTableData.fields = [{ key: 'rank', sortable: true },{ key: 'company', sortable: true },{ key: 'total_funding', sortable: true },{ key: 'last_funding_on', sortable: true },{ key: 'founding_date', sortable: true }];
        this.rankingTableData.items  = res.body.rows.map( (data, index )=>{ 
                                              return { 
                                                rank: index + 1,
                                                company: data,
                                                total_funding: data.total_funding_usd,
                                                last_funding_on: data.last_funding_on,
                                                founding_date: data.founding_date
                                              }; 
                                            } 
                                          ).slice(0,50);
        //this.$forceUpdate();
      });
    },
    setInvestorRankingTableData(){
      
      let dataToPost = {
                          //keywords: this.keywords,
                          
                          full_query: this.selectedTopic.full_query,
                          freq: "Y",
                          period: 5
                        };
      
      Vue.http.post(`${Config.config.base_host}/topics_v1/adhoc/investors`, dataToPost ).then((res) => {
        this.investorRankingData        = {};
        this.investorRankingData.fields = [{ key: 'rank', sortable: true }, { key: 'company', sortable: true }, { key: 'total_investment', sortable: true }, { key: 'invested_in_topic', sortable: true }, { key: 'last_invested', sortable: true }];
        this.investorRankingData.items  = res.body.map( (data, index )=>{ 
                                              return { 
                                                rank: index + 1,
                                                company: data.investor,
                                                invested_in_topic: data.stats.keyword_participation_usd,
                                                total_investment: data.stats.total_participation_usd,
                                                
                                                last_invested: data.stats.keyword_last_invested
                                              }; 
                                            } 
                                          ).slice(0,50);
        //this.$forceUpdate();
      });
      
    },
    setMediaChartData(){
      
      let dataToPost = {
                         
                         
                          full_query: this.selectedTopic.full_query,
                          freq: "M",
                          period: 12
                        };
      
      this.analyzingData = true;
      Vue.http.post(`${Config.config.base_host}/topics_v1/adhoc/media_attention`, dataToPost ).then((res) => {
        this.mediaAttentionData           = {};
        this.mediaAttentionData.labels    = Object.keys( res.body.timeline ).sort();
        this.mediaAttentionData.datasets  = [ 
                                              { 
                                                borderColor: this.line_chart_colors[0],
                                                backgroundColor:"transparent" ,
                                                data: this.mediaAttentionData.labels.map((x) => res.body.timeline[x]['ratio'] )
                                              }
                                            ];
        this.analyzingData = false;
        //this.$forceUpdate();
      });
      
    },
    setFundingIndicators(){
      let dataToPost = {
                        
                         
                          full_query: this.selectedTopic.full_query,
                          freq: "Y",
                          
                          period: 7
                        };
      Vue.http.post(`${Config.config.base_host}/topics_v1/adhoc/fundings`, dataToPost ).then((res) => {
        this.totalFundingData           = {};
        this.totalFundingData.labels    = Object.keys( res.body.timeline ).sort();
        this.totalFundingData.datasets  = [ 
                                            { 
                                              label: 'Total fundings', 
                                              yAxisID: "Fundings",
                                              order: 2,
                                              backgroundColor: this.bar_chart_colors[0],
                                              data: this.totalFundingData.labels.map((x) => res.body.timeline[x]['topic_funding_count'] )
                                            },
                                            { 
                                              label: 'Total raised ( in milions )', 
                                              yAxisID: "Raised",
                                              type: "line",
                                              order: 1,
                                              tension: 0,
                                              borderColor: this.line_chart_colors[0],
                                              backgroundColor:"transparent" ,
                                              data: this.totalFundingData.labels.map((x) => ( res.body.timeline[x]['total_raised'] / 1000000) )
                                            }
                                          ];

        this.totalFundingData.labels    = this.totalFundingData.labels.map( date =>{ return date.split("-")[0] });
        //this.$forceUpdate();
      });
    },
    setDealShareData(){
      let dataToPost = {
                        
                         
                          full_query: this.selectedTopic.full_query,
                          freq: "Y",
                          period: 7
                        };
      Vue.http.post(`${Config.config.base_host}/topics_v1/adhoc/investments`, dataToPost ).then((res) => {
        this.dealShareData           = {};
        this.dealShareData.labels    = Object.keys( res.body.timeline ).sort();
        this.dealShareData.datasets  = [ 
                                           
                                            { 
                                              label: 'Seed', 
                                              backgroundColor: this.bar_chart_colors[0],
                                              data: this.dealShareData.labels.map((x) => res.body.timeline[x].investments['seed'] )
                                            },
                                            { 
                                              label: 'Series A', 
                                              backgroundColor: this.bar_chart_colors[1],
                                              data: this.dealShareData.labels.map((x) => res.body.timeline[x].investments['series_a'] )
                                            },
                                            { 
                                              label: 'Series B', 
                                              backgroundColor: this.bar_chart_colors[2],
                                              data: this.dealShareData.labels.map((x) => res.body.timeline[x].investments['series_b'] )
                                            },
                                            { 
                                              label: 'Series C', 
                                              backgroundColor: this.bar_chart_colors[3],
                                              data: this.dealShareData.labels.map((x) => res.body.timeline[x].investments['series_c'] )
                                            },
                                            { 
                                              label: 'Series D', 
                                              backgroundColor: this.bar_chart_colors[4],
                                              data: this.dealShareData.labels.map((x) => res.body.timeline[x].investments['series_d'] )
                                            },
                                            { 
                                              label: 'Series E+', 
                                              backgroundColor: this.bar_chart_colors[4],
                                              data: this.dealShareData.labels.map((x) => res.body.timeline[x].investments['series_e+'] )
                                            },
                                             { 
                                              label: 'Corporate', 
                                              backgroundColor: this.bar_chart_colors[7],
                                              data: this.dealShareData.labels.map((x) => res.body.timeline[x].investments['corporate'] )
                                            },
                                             { 
                                              label: 'Other', 
                                              backgroundColor: this.bar_chart_colors[5],
                                              data: this.dealShareData.labels.map((x) => res.body.timeline[x].investments['Other'] )
                                            }
                                          
                                          ];

        this.dealShareData.labels = this.dealShareData.labels.map( date =>{ return date.split("-")[0] });
        //this.$forceUpdate();
      });
    },
    setFoundingsDataIndicators(){
      let dataToPost = {
                        
                          full_query: this.selectedTopic.full_query,
                          freq: "Y",
                          period: 7
                        };
      Vue.http.post(`${Config.config.base_host}/topics_v1/adhoc/foundings`, dataToPost ).then((res) => {
        this.foundingsData           = {};
        this.foundingsData.labels    = Object.keys( res.body.timeline ).sort();
        this.foundingsData.datasets  = [ 
                                            { 
                                              label: "No. of Foundings",
                                              backgroundColor: this.bar_chart_colors[0],
                                              data: this.foundingsData.labels.map((x) => res.body.timeline[x]['topic_founding_count'] )
                                            }
                                          ];
        this.foundingsData.labels = this.foundingsData.labels.map( date =>{ return date.split("-")[0] });
        //this.$forceUpdate();
      });

    },
    add_to_project(selectedProjectStage, topic_list) {
      this.$store.dispatch("start_loading");
      let ids = topic_list.map((x) => { return x._id });
      Vue.http.post(`${Config.config.base_host}/projects/${selectedProjectStage}/project_topics/bulk`, {topic_ids: ids}).then(() => {
        
        this.$store.dispatch("stop_loading");
        
        location.href = "/projects/"+selectedProjectStage+'/topics';
        this.$store.state.sidebar_project.selected_list = [];
        this.$bvToast.toast("The topics have been added to the project", {
          title: 'Added to project',
          variant: 'primary',
          autoHideDelay: 1000,
        });

      });

    },
  },
  components: {
    BarChartStacked,
    LineChartNew,
    ProjectStageSelect,
    Logo,
    TaxonomyTagger,
    Topic2Modal,
    TopicKeywordSelect
  }
}

</script>
<style lang="scss" scoped>
  @import '@/_vars.scss';

  .ibm{
    display: inline-block;
    vertical-align: middle;
  }

  .keywords-holder{
    max-width: calc( 100% - 150px );
  }
  .ranking-table-heading {
    width: calc( 100% - 130px );
  }
  .topic-chart-holder{
    background: #d4dfe8;
    border-radius: 5px;
  }
  .inner-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .topic-chart-holder{
      width: calc( 50% - 8px );
    }
  }
  .table-holder{
    white-space: nowrap;
    overflow: auto;
  }
  .outer-container{
    border-bottom: 1px solid $gray-700;
  }
  .topic-heading{
    color: $gray-700;
  }


  @media screen and ( max-width: 1200px ) {
    .inner-container{
      .topic-chart-holder{
        width: 100%;
      }
    }
  }
</style>

<style lang="scss" >
  @import '@/_vars.scss';

  .topic-table{
    thead th {
      font-size: 13px;
      text-align: left;
    }
  }
  .table-bordered th, .table-bordered td {
    border-color: #fff;
    text-align: center;
    color: $gray-700;
  }
</style>
