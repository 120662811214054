<template lang="pug">

  .project-card
    b-card(no-body, style="cursor:pointer")
      .row.p-2
        .col-12
          .float-right
            b-dropdown(size="sm" variant="link" no-caret, right).comment-dd.option-dropdown
              template(v-slot:button-content)
                i.fas.fa-ellipsis-h.gray-info

              b-dropdown-item(size="sm" @click="open(project, topic)") Edit
              b-dropdown-item(size="sm" @click="open(project, topic)") Show details
              b-dropdown-item(size="sm" @click="removeFromProject(project, topic)") Delete from project

          
          .text-nowrap.uc-name {{topic.name}}
          .text-muted.small {{topic.description}}
                
</template>

<script>
// @ is an alias to /src
import debounce from 'lodash/debounce';
import Config from '@/config'
import Vue from 'vue'
import Avatar from 'vue-avatar'
export default {
  name: 'TopicCard',
  props: {
    "topic":  {
      default: null
    }, 
    "collpased": {
      type: Boolean,
      default: false
    }, 
    "selectMode": {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
        uid: null,
        options: null
    }
  },

  mounted: function() {
     this.uid = this._uid;
     
  },

     
  
  methods: {
    
   onSearch(search, loading) {
      
      //loading(true);
      this.search(loading, search, this);

    },

    search: debounce((loading, search, vm) => {
      
      Vue.http.get(`${Config.config.base_host}/users/search/autocomplete?q=${escape(search)}`).then(res => {
        vm.options = res.body;
        loading(false);
      });

    }, 350)
  },

  components: {
    Avatar
  }
}

</script>
<style lang="scss" scoped>
.option, .selected {
  //width: 300px;
}



.uc-name {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
  display: inline-block;
}
</style>
