<template lang="pug">

  .assessments(v-if="survey_template && surveys != null && $store.state.user")
    
    .row.mt-3
        
        .col-3
           
           
            b-list-group(style="overflow-y: auto", v-if="surveys != null")
                b-list-group-item(:active="selected_survey == null" button, @click="selected_survey=null").mb-2 
                    .label Statistics
                    div
                        h3 {{surveys.length}}
                        .label.gray-info Assessements<br/>
                  
                template(v-if="!has_own_assessment")
                    b-button(variant="outline-primary", :active="selected_survey && selected_survey.owner_id == $store.state.user.id" button, @click="create_new()").mb-2.d-flex.justify-content-between.align-items-center Give own assessment    
                
                template(v-for="survey in surveys.filter(x => x.owner_id == $store.state.user.id)")
                    b-list-group-item(:active="selected_survey == survey" button,  @click="selected_survey=survey").mb-2.d-flex.justify-content-between.align-items-center 
                      
                        User(:user="survey.relations.owner_id", :showName="true", :size="25")
                        .text-gray.small {{survey.updated_at | utcFrom}} 
                                
                template(v-for="survey in surveys.filter(x => x.owner_id != $store.state.user.id)")
                    b-list-group-item(:active="selected_survey == survey" button,  @click="selected_survey=survey").d-flex.justify-content-between.align-items-center 
                       
                        User(:user="survey.relations.owner_id", :showName="true", :size="25")
                        .text-gray.small {{survey.updated_at | utcFrom}} 

        .col-9(style="max-width: 800px")
            template(v-if="selected_survey == null")
                template(v-if="aggregation")
                    SurveyAggregation(:survey_template="survey_template", v-model="aggregation")
       
            template(v-if="selected_survey")
                Survey(:survey_template="survey_template", @saved="getData()" v-model="selected_survey", :user="selected_survey.relations.owner_id", :editable="selected_survey.owner_id == $store.state.user.id", :edit_mode="selected_survey.owner_id == $store.state.user.id")
       



</template>

<script>

import Vue from 'vue';
import Config from '@/config'
import Survey from '@/components/Survey.vue'
import SurveyAggregation from '@/components/SurveyAggregation.vue'
import User from '@/components/User.vue'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'Assessement',
  
  data: function () {
      return {
          survey_template: null,
          selected_survey: null,
          surveys: null,
          aggregation: null,
          has_own_assessment: false
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    this.getData();
  
  },

  watch: {
    
  },

  computed: {
       
        ...mapFields([
         

        ]),
  },

  
  methods: {

      create_new() {
        this.selected_survey = {"relations": {"owner_id": this.$store.state.user}, "custom_data": {}, "owner_id": this.$store.state.user.id, "survey_type": "use_case_expert_assessment", "topic_id": this.$route.params.topic_id};
      },
    
      getData() {
        
        this.$store.dispatch("start_loading");
        
        Vue.http.get(`${Config.config.base_host}/survey_templates/SurveyTemplate:use_case_assessment`).then(res => {
            this.survey_template = res.body;
            
            this.$store.dispatch("stop_loading");
        });

        Vue.http.post(`${Config.config.base_host}/surveys/search`, {"survey_template_id": ["SurveyTemplate:use_case_assessment"], "topic_id": [this.$route.params.topic_id]}).then(res => {
            this.surveys = res.body;
         
            this.has_own_assessment = this.surveys.filter(x => x.owner_id == this.$store.state.user.id).length > 0;
            this.$store.dispatch("stop_loading");
        });

        Vue.http.post(`${Config.config.base_host}/surveys/aggregation`, {"survey_template_id": ["SurveyTemplate:use_case_assessment"], "topic_id": [this.$route.params.topic_id]}).then(res => {
            this.aggregation = res.body;
            this.$store.dispatch("stop_loading");
        });
      
      }
     
  },
  components: {
      Survey,
      SurveyAggregation,
      User
  }
}

</script>
<style lang="scss" scoped>
    a {
        text-decoration: none !important;
    }
</style>