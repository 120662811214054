<template lang="pug">
  .chart-holder.bar-chart
    CommonChart( :chartData="chart_data", :chartOptions="chart_options", :chartType="chartType")

</template>

<script>
import CommonChart from "./CommonChart";
export default {
  name: "LineChartNew",
  props:{
		chart_data:Object,
    chart_options: Object
	},
	data() {
		return {
			chartType: "line"
		};
	},
	components: {
    CommonChart
	}
};

</script>

<style></style>