<template lang="pug">  
  .filter-group.taxonomy-tagger( :class="{ 'read-only-mode': read_only}" :id="'taxonomyTagger' + Date.now()" )
    .tagger-selection-holder( v-if="!read_only")
      .tagger-title.mt2.pb-2 {{title}}
      .tag-options-holder.p3(v-show="can_be_empty || available_tags.length > 0")  
        v-select(
                  @focus.native="cleanSearch()", 
                  @click.native="cleanSearch()", 
                  :selectable="isTagSelectable",
                  :taggable="taggable",
                  :id="id", 
                  :multiple="multiple", 
                  :placeholder="placeholder",
                  v-model="selected_options" 
                  :options="available_tags", 
                  :filterable="true",
                  :select-on-tab="true",
                  :close-on-select="true",
                  :key="id"
                )
          template(#no-options="{ search, searching, loading }") Nothing found
    .tagger-selection-holder(v-if="!can_be_empty && available_tags.length == 0 && !read_only")
      v-select(placeholder="Loading ...", :disabled="true")     
    

    .tagger-selections( :class="{ 'mt-3': !read_only}")
      //- Sortable prop is true and not readonly
      draggable( v-if=" !read_only && sortable && selected_options && selected_options.length > 0" :list="selected_options" ghost-class="ghost" )
        .selected-tag.v1.pt-1.mr-2.mb-2.clearfix( v-for="selected_option in selected_options" :key="selected_option" :class="{selected_tag_class, 'px-2': !edit_option || edit_option != selected_option, 'edit-enabled': edit_option && edit_option == selected_option}" )
          input.tagger-input.px-2( v-if="enable_edit_selection && edit_option && edit_option == selected_option" @keyup.enter="updateValueOnEnter" v-model="edit_text"  placeholder="Update the selection" )
          template( v-else  )
            .option-text.float-left( @click="enableOptionEdit( selected_option )" ) {{selected_option}}
            b-btn-close.pl-3.close-icon.float-right( @click="removeSelectedOption( selected_option )" )
      //- Sortable prop is false and not read only
      template( v-else-if="!read_only && !sortable" v-for="selected_option in selected_options")
        .selected-tag.v2.pt-1.mr-2.mb-2.clearfix( :key="selected_option" :class="{selected_tag_class, 'px-2': !edit_option || edit_option != selected_option, 'edit-enabled': edit_option && edit_option == selected_option}" )
          input.tagger-input.px-2( v-if="enable_edit_selection && edit_option && edit_option == selected_option" @keyup.enter="updateValueOnEnter" v-model="edit_text"  placeholder="Update the selection" )
          template( v-else  )
            .option-text.float-left( @click="enableOptionEdit( selected_option )" ) {{selected_option}}
            b-btn-close.pl-3.close-icon.float-right( @click="removeSelectedOption( selected_option )" )
      //- read only
      template(v-else-if="read_only" v-for="selected_option in selected_options")
        .selected-tag.v3.py-0.px-2.mr-2.mb-2.clearfix( :key="selected_option" )
          .option-text.float-left( @click="enableOptionEdit( selected_option )" ) {{selected_option}}
    
      
</template>

<script>

import draggable from "vuedraggable";

export default {
  name: 'TaxonomyTagger',
  props: {
    "tags_list": {
      type: Array,
      default: function(){
                return [];
              }
    },
    "id":  {
      default: "taxonomyTagger" + Date.now()
    }, 
    "multiple": {
      type: Boolean,
      default: true
    },
    "taggable": {
      type: Boolean,
      default: false
    },
    "can_be_empty": {
      type: Boolean,
      default: false
    },
    "sortable": {
      type: Boolean,
      default: false
    },
    "enable_edit_selection": {
      type: Boolean,
      default: false
    },
    "selections": {
      type: Array,
      default: function(){
                  return [];
               }
    },
    "placeholder": {
      type: String,
      default: ""
    },
    "title": {
      type: String,
      default: ""
    },
    "read_only":{
      type: Boolean,
      default: false
    },
    "selected_tag_class":{
      type: String,
      default: ""
    }
  },
  components:{
    draggable
  },
  data: function () {
    return {
        selected_options: [],
        available_tags: [],
        edit_option: "",
        edit_text: "",
    }
  },

  watch: {
    tags_list: function( newList , oldList){
      // Need to watch tags_list prop as data might change without remounting
      if ( newList && oldList ){
        let differences = this.$_.xor( newList, oldList );
        console.log( "Tags Diff present for ", this.id );
        differences.length && this.intitiateComponent();
      }
    },
    selections: function( newSelections , oldSelections){
      // Need to watch selections prop as data might change without remounting
      if ( newSelections && oldSelections ){
        let differences = this.$_.xor( newSelections, oldSelections );
        console.log( "Diff present for ", this.id );
        differences.length && this.parseSelections();
      }
    },
    selected_options: function(val, oldVal) {
      // Emitting Results ( Array of IDs ) back to parent
      if(val != oldVal && oldVal && val ){
        console.log(" Emitted value up: ", val);
        this.$emit("input", val);
      } 
    }
  },
  beforeMount: function() {
    this.intitiateComponent();
  },
  mounted: function() { 

  },

 
  computed: {
  },
  methods: {

    intitiateComponent(){
      this.cleanSearch( );
      this.parseSelections();
    },
    parseSelections(){
      // compute selected options based on the selections prop

      if( this.available_tags && this.available_tags.length > 0 ){
        this.selected_options = [...this.selections];
        console.log("Selection parsing complete for ", this.id, this.selected_options);
      }else{
        console.log( "Can't Parse ", this.id, " currently.");
      }
      
    },

    enableOptionEdit( tag_option ){
      if( this.taggable ){
        this.edit_option = tag_option;
        this.edit_text   = tag_option;
      }
      
    },
    updateValueOnEnter(  ){
      
      let selected_index = this.selected_options.indexOf( this.edit_option );
      if( selected_index != -1 ){
        this.selected_options[ selected_index ] = this.edit_text;
        
      }
      if( this.tags_list.indexOf( this.edit_text ) == -1 ){
        this.tags_list.push( this.edit_text ); 
      }
      this.edit_text = this.edit_option = "";
      
    },
    removeSelectedOption( tag_option ){
      this.selected_options = this.selected_options.filter( ( tagOption )=>{
        return tagOption != tag_option;
      } );

    },

    isTagSelectable( tag_option ){
      if( this.selected_options && this.selected_options.length ){
        let arr = this.selected_options.filter( ( tagOption )=>{
          return tagOption == tag_option;
        });
        return !arr.length;
      }
      return true;
    },

    cleanSearch( ) {
      this.available_tags = [ ...this.tags_list ]; 
    }
  }
}

</script>
<style lang="scss">
  @import '@/_vars.scss';

  .taxonomy-tagger{
    .vs__selected {
      display: none;
    }
    .selected-tag {
      display: inline-block;
      vertical-align: middle;
      border: 1px solid #1eaf1e;
      border-radius: 5px;
    }
    .edit-enabled{
      border-color: $gray-700;
    }
    .tagger-input{
      display: inline-block;
      padding: 0px;
      background: $gray-500;
      outline: none;
      border: none;
      border-radius: 0px;
    }
 
    .tagger-selection-holder{
      max-width: 400px;
    }
    .close-icon{
      //color: #1eaf1e;
    }
    &.read-only-mode{
      .selected-tag {
        border: 1px solid $gray-700;
        display: inline-block;
        border-radius: 5px;
      }
      .tagger-selections {
        min-height: auto;
      }
    }
  }
</style>
