<template lang="pug">

  .overview

    template(v-if="show_industries")
      
      .row()
      
        .col-4.col-md-3.col-lg-2.col-xl-2
          .topic-badge-my( v-bind:class="{'active': !selectedIndustry}" @click="getFollowTopic()", style="cursor: pointer").mt-2.align-middle My topics
      
      .row.mt-4()
        .col-12
          label Industries

      .row(v-if="industries")
        .col-4.col-md-3.col-lg-2.col-xl-2(v-for="topic in industries.rows")
          .topic-badge( v-bind:class="{'active': (selectedIndustry && selectedIndustry._id == topic._id)}", @click="getTopics(topic)", style="cursor: pointer").mt-2.align-middle {{topic.name}}
    
    .row.mt-5(v-if="topics && topics.length > 0")
      .col-8
        label Topics
      .col-4
        .float-right
          b-form(inline)

            b-select(v-model="sortBy", :options="[{'value': 'trend_media_attention', 'text': 'Trend Score'}, {'value': 'name', 'text': 'Name'}]", size="sm")
    b-overlay(:show="$store.state.loading").w-100
      b-row(cols="1" cols-sm="1" cols-md="2" cols-lg="4").mt-2.form-row
          //b-col().mb-2 
            
            b-card(no-body)
              b-card-header()
                    | New topic
              b-card-body.h-mouseover-parent.text-center
                    
                    h1.mt-3.mb-3
                      b-link(:to="'/topics/add_topic'" )  
                        i.fas.fa-plus-circle()
          div(v-if="topics && topics.length == 0").text-muted.mt-3 There are no topics in this category.
          
          b-col(v-for="topic in $_.orderBy(topics, item => (sortBy == 'trend_media_attention' ? item.custom_data.trend_media_attention.growth : item.name), ['asc'])").d-flex.align-items-stretch.mb-3.w-100
              
                  b-card(no-body).w-100
                    b-card-header(style="min-height: 4.5em")

                      //.float-right.small()
                        b-dropdown(id="dropdown-offset" offset="0" text="Offset Dropdown" size="sm" variant="link" no-caret).p-0.remove-project-btn
                          template(v-slot:button-content)
                            i.fas.fa-ellipsis-h.gray-info.p-0
                          //b-dropdown-item(size="sm" @click="clone_topic(topic)") Clone topic
                          //b-dropdown-item(size="sm" @click="delete_project_topic(topic)") Delete topic
                          b-dropdown-item(size="sm" @click="delete_project_topic(topic)", v-if="project") Remove from project


                      router-link(:to="'/topics/details/' + topic._id + ''")
              
                          b.truncate-2 {{topic.name}}
                          .text-muted(v-if="topic.relations.curator").small Curated by hy

                        
                    b-card-body.h-mouseover-parent
                      .text-muted {{topic.description}}
                      

                      //.badge.badge-primary(v-if="topic.custom_data.trend_funding").mr-2 {{topic.custom_data.trend_funding.growth}}
                      .row
                        .col-6
                          .d-flex.justify-content-between.align-items-center

                            .score(v-if="topic.custom_data.trend_quaterly_funding", :style="{'background-color': heatMapColorforValue(topic.custom_data.trend_quaterly_funding.growth)}").text-center <span v-if="topic.custom_data.trend_funding.growth > 0">+</span>{{Math.round(topic.custom_data.trend_quaterly_funding.growth*100)}}%
                              .small Funding trend
                              .small  last quarter 
                            
                           
                             
                          
                            .score(v-if="topic.custom_data.trend_media_attention", :style="{'background-color': heatMapColorforValue(topic.custom_data.trend_media_attention.growth)}").text-center <span v-if="topic.custom_data.trend_media_attention.growth > 0">+</span>{{Math.round(topic.custom_data.trend_media_attention.growth*100)}}%
                              .small Media trend 
                              .small last year
                        
                        .col-6        
                            .float-right
                    
                              div(v-if="project")
                                
                                .h-mouseover-show
                                  
                                    b-btn(@click="openTopicSidebar(topic)", size="sm", variant="outline-secondary", v-if="project") Evaluate
                                
                                .h-mouseover-hide
                                      b-badge {{topic.index_space.projects.find(x => x._id == project._id).stage}}
                                      b-badge {{topic.index_space.projects.find(x => x._id == project._id).group}}

                              h-pin(:item_id="topic._id", item_type="Topic")#project-add-edit 
                                  | Follow
                                  template(slot="unfollow")
                                    | Unfollow
                                  template(slot="following")
                                    | Following

        
    //.row.mt-5(v-if="topics && topics.length > 0")
      .col-8
        label Upcoming Keywords (auto-discovered)
      .col-4
        .float-right
          b-form(inline)

        
</template>

<script>
import Vue from 'vue';
import Config from '@/config'

import ProjectCard from '@/components/ProjectCard.vue'
import { mapFields } from 'vuex-map-fields';

export default {
  
  name: 'ProjectOverview',
  props: {
 
    "show_industries": {
      type: Boolean,
      default: true
    }, 
    "project": {
      type: Object,
      default: null
    }
  },
  data: function () {
      return {
        industries: null,
        selectedIndustry: null,
        topics: null,
        sortBy: "name"
      }
  },

  mounted: function() {
   
    this.host = Config.config.base_host;
    this.getIndustries();
    this.sidebar_visible = false;
  
  },

  watch: {
    
    'results': function () {
      this.results.forEach(it => {
        this.getTrends(it._id);
      });
      
    },
    
  },

  computed: {
       
        ...mapFields([
            'topics.results',
            'topics.trends',
            'sidebar_project.selected_item',
            'sidebar_visible'

        ]),
  },

  
  methods: {



    heatMapColorforValue(percentage) {
      

      if(percentage < -50) return 'hsl(18, 100%, 50%, 0.2)';
      if(percentage < 0) return 'hsl(50, 100%, 50%, 0.2)';
      if(percentage < 50) return 'hsl(90, 100%, 50%, 0.2)';
      if(percentage > 100) return 'hsl(150, 100%, 50%, 0.2)';

      
       
    },

    delete_project_topic(topic) {
   
      topic.relations.pt = topic.index_space.projects.find(x => x._id == this.project._id)

       let pt = {"_id": this.project._id + '::' + topic._id, "deleted_at": true, "group":  topic.relations.pt.group, "custom_data": {},"stage": topic.relations.pt.stage};

       Vue.http.put(`${Config.config.base_host}/project_topics/${pt._id}`, pt).then(() => {
          this.getTopics();
          this.$store.dispatch("stop_loading");

        });
    },

    openTopicSidebar(topic){
      console.log(topic.index_space);
      topic.relations.pt = topic.index_space.projects.find(x => x._id == this.project._id)
      
      if(!topic.relations) topic.relations = {};
      topic.relations.evaluate = true;
      topic.relations.project = this.project;
      this.selected_item = topic;
    },

      getTrends(topic_id) {
        this.$store.dispatch("start_loading");
         Vue.http.get(Config.config.base_host+'/topics_v1/media_attention/'+topic_id).then(resp => {
            this.trends[topic_id] = resp.body;
            this.$forceUpdate();
            this.$store.dispatch("stop_loading");
        }); 
      },
      getIndustries() {
        
        Vue.http.post(Config.config.base_host+'/topics_v1/search', {"topic_type": "industry"}).then(resp => {
         
          this.industries = resp.body;
          this.industries.rows = this.$_.sortBy(this.industries.rows, ["name"])

          this.getTopics(this.industries.rows[0]); 
          
        }); 

      },

      getTopics(industry) {
        this.selectedIndustry = industry;
        
        let params = {};
        
        if(this.project) {
          params = {"topic_type": "topic", "project_id": [this.project._id]};
        } else {
          params =  {"topic_type": "topic", "parent_id": [industry._id]};
        
        }

        params['limit'] = 100;
        
        Vue.http.post(Config.config.base_host+'/topics_v1/search', params).then(resp => {
         
          this.topics = resp.body.rows;
        }); 
      },

       getFollowTopic() {
      
        this.selectedIndustry = null;
       
        let params = {"topic_type": "topic", "bookmarked": true};
       
        Vue.http.post(Config.config.base_host+'/topics_v1/search', params).then(resp => {
         
          this.topics = resp.body.rows;
        }); 
      }


     
  },
  components: {
      ProjectCard
  }
}

</script>
<style lang="scss" scoped>

    .score {
      display: inline-block;
      
      min-width: 80px;
      margin-right: 3px;
      padding: 2px;
      font-size: 0.75em !important;
    }

    a {
        text-decoration: none !important;
    }
</style>