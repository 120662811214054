
<template lang="pug">
    .main(v-if="internal_value")
        .row.mb-2
            .col-8
                h6 {{survey_template.name}} - Aggregation
            .col-4
                .float-right 
                    b-btn(variant="link", size="sm", v-if="editable && !edit_mode", @click="edit_mode=true").p-0.text-gray
                        i.fas.fa-pen.text-gray
        .row
            .col
                div(v-for="group in survey_template.groups")
                        b-card(:title="group.name")
                            
                            b-card-text() {{group.description}}
                            b-table(:fields="fields", :items="get_fields_by_group(group.name).map(x => Object.assign({'Name': x.label}, internal_value[x.name]) )")
                      
                            
     
</template>

<script>

// @ is an alias to /src

export default {
  name: 'SurveyAggregation',
  props: {
    "value":  {
      default: {}
    },
    "survey_template": {
        default: null
    },
    "edit_mode":  {
      default: false
    },
    "editable":  {
      default: false
    },
    "inline":  {
      default: true
    },
    "user":  {
      default: null
    },
 
  },

  watch: {

    value: {
      deep: true,
      handler(val) {
        this.internal_value = val;
      }
    },

    internal_value: {
      deep: true,
      handler(val) {
        this.$emit("input", val);
      }
    }
  },


  data: function () {
    return {
        internal_value: null,
         fields: [
            'Name',
            'count',
            {
                "key": "mean",
                "label": "Mean",
                formatter: function(x) {return Math.round(x*100)/100},
                sortable: true,
            },
             {
                "key": "std",
                "label": "Std",
                formatter: function(x) {return Math.round(x*100)/100},
                sortable: true,
            },
                {
                "key": "min",
                "label": "Min",
                formatter: function(x) {return Math.round(x*100)/100},
                sortable: true,
            },
                {
                "key": "25%",
                "label": "25%",
                formatter: function(x) {return Math.round(x*100)/100},
                sortable: true,
            },
            {
                "key": "50%",
                "label": "50%",
                formatter: function(x) {return Math.round(x*100)/100},
                sortable: true,
            },
            {
                "key": "75%",
                "label": "75%",
                formatter: function(x) {return Math.round(x*100)/100},
                sortable: true,
            },
             {
                "key": "max",
                "label": "Max",
                formatter: function(x) {return Math.round(x*100)/100},
                sortable: true,
            },

          

        ],      
    }
  },

  mounted: function() {
     this.uid = this._uid;
     this.internal_value = this.value;
  },

  methods: {
      
      get_fields_by_group(name) {
          return this.survey_template.data_fields.filter(x => x.group == name);
      }
  },

  components: {
   
  }
}

</script>
<style lang="scss" scoped>
.feed-image {
    width: 50px;
    height: 50px;
}
</style>
