<template lang="pug">
  .search-filter().mr-3.mb-5
    b-form(autocomplete="off")

        //FilterGroup(title="Search in" :collpased="true").mt-1
          b-form-group()
            b-check(checked) Ecosystem
            b-check(checked) Scouting Database

        FilterGroup(title="Search", :collpased="true")

          b-form-group( id="name", :label="'Keyword'", label-for="kw", label-size="sm")
            v-select(placeholder="Enter keyword and press enter", size="sm",  @input="getData()", v-model="keyword", :taggable="true", :multiple="true").small
        
        FilterGroup(title="Filter", :collpased="false").mt-2
          
          
            b-form-group(id="org_tags-group", :label="field.name", label-for="org_tags", label-size="sm", v-for="field in project_config", v-if="field.topic_type != 'sub_vertical' || custom_data['vertical'].indexOf('db8c995d6b52770ef61bc39821d3a3d5') > -1")
              TaxonomySelect(id="stage"
                          @input="getData()"
                          :options="[]",
                          :topic_type="field.topic_type",
                          :multiple="true"
                          :select-on-tab="true"
                          v-model="custom_data[field.topic_type]").small

            b-form-group(id="tags-group", label="Lists", label-for="lists", label-size="sm")
              WatchlistSelect(id="lists"
                        size="sm", 
                        :multiple="true"
                        @input="getData()"
                        :close-on-select="false"
                        :select-on-tab="true"
                        item_type="topic"
                        v-model="watchlist_id").small
            
            b-form-group( id="project", :label="'Projects'", label-for="kw", label-size="sm")
              ProjectStageSelect(id="projectStageAdd" v-model="project_id",  @input="getData()", :multiple="true", :create_option="false").small
       
        FilterGroup(title="Use case quality", :collpased="false").mt-2

            
            b-form-group(id="input-group-use-case-quality", label="Popularity (used in projects)", label-for="input-2", label-size="sm")
                b-input-group(id="input-group-last_funding_on")
                  b-input(type="number" placeholder="From", size="sm", v-model="number_projects[0]", @change="getData()").mr-3
                  div(style="margin-top: 5px") &nbsp; &dash; &nbsp;
                  b-input(type="number" placeholder="To", size="sm", v-model="number_projects[1]", @change="getData()").ml-3

            b-form-group(id="input-group-use-case-quality", label="Assigend companies", label-for="input-2", label-size="sm")
                b-input-group(id="input-group-last_funding_on")
                  b-input(type="number" placeholder="From", size="sm", v-model="number_organizations[0]", @change="getData()").mr-3
                  div(style="margin-top: 5px") &nbsp; &dash; &nbsp;
                  b-input(type="number" placeholder="To", size="sm", v-model="number_organizations[1]", @change="getData()").ml-3

            b-form-group(id="input-group-use-case-quality", label="Number surveys/assessements", label-for="input-2", label-size="sm")
                b-input-group(id="input-group-last_funding_on")
                  b-input(type="number" placeholder="From", size="sm", v-model="number_surveys[0]", @change="getData()").mr-3
                  div(style="margin-top: 5px") &nbsp; &dash; &nbsp;
                  b-input(type="number" placeholder="To", size="sm", v-model="number_surveys[1]", @change="getData()").ml-3

        //.mt-3
          b-button(variant="outline-secondary", @click="getData()", size="sm")#project-search-button.form-control Search Use Cases
          

</template>

<script>
// @ is an alias to /src

import Config from '@/config'


import FilterGroup from '@/components/FilterGroup'
import TaxonomySelect from '@/components/TaxonomySelect'
import ProjectStageSelect from '@/components/ProjectStageSelect'
import WatchlistSelect from '@/components/WatchlistSelect'
import VueSlider from 'vue-slider-component'
import { mapFields } from 'vuex-map-fields';
import 'vue-slider-component/theme/antd.css'

export default {
  
  name: 'UseCaseOverviewFilter',

  data: function () {
    return {
      query: null,
      project_config: [{"name": "Industry", "topic_type": "industry"},{"name": "Enterprise Function", "topic_type": "enterprise_function"},{"name": "Vertical",  "topic_type": "vertical"}, {"name": "Sub-Vertical", "topic_type": "sub_vertical"}]
    }
  },

  mounted: function() {
  
      this.host = Config.config.base_host;
      if(this.$route.query.keyword) {
        this.keyword = [this.$route.query.keyword];
        this.getData();
      }
  },

  computed: {

    ...mapFields([
        'usecase.filter.project_id',
        'usecase.filter.keyword',
        'usecase.filter.custom_data',
        'usecase.filter.watchlist_id',
        'usecase.filter.organization_id',
        'usecase.filter.number_projects',
        'usecase.filter.number_organizations',
        'usecase.filter.number_surveys',
        'usecase.filter.updated_at'
    ]),
  },
  
  methods: {
    
    getData() {
        this.$emit('search');      
    },

  },
  components: {
    FilterGroup,
    TaxonomySelect,
    ProjectStageSelect,
    WatchlistSelect,
    VueSlider
  }
}

</script>
<style lang="scss" scoped>

</style>
