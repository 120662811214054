<template lang="pug">
  .filter-group

        b-card(no-body).ib-card
            template(v-slot:header)
                div(style="cursor: pointer")
                    .row
                        .col
                          .text-truncate
                            span(:class="{'text-primary': false}").mb-0 {{title}}
                        .col
                          .float-right
                            slot(name="head_addition")
                          
                          
                                 
            b-collapse(:id="uid +'-collapse-1'" :visible="collpased",  @shown="update()",  @hidden="update()")
              b-card-body()
                slot()
              

</template>

<script>
// @ is an alias to /src


export default {
  
  name: 'FilterGroup',
  props: ["title", "collpased", "help"],
  data: function () {
    return {
      uid: null,
    }
  },

  mounted: function() {
     this.uid = this._uid;
  },

     
  
  methods: {
      update() {
        this.$emit("update");
      }
    

  },

  components: {
    
  }
}

</script>
<style lang="scss" scoped>
.collapsed  .when-opened,
:not(.collapsed) .when-closed {
  display: none;
}

.card {
  border: 0;
  padding: 0px;
  background-color:transparent;

  .card-header {
    background-color:transparent;
    padding: 6px 0px;
    text-transform: none;
  }

  .card-body{
   
    padding: 6px 0px;
  }
}


</style>
