<template lang="pug">

.overview.sticky-container(data-v-sticky-container)
  UseCaseModal(ref="topicUseCaseModal")
  b-overlay(:show="$store.state.loading")
    b-row()
        .col-3.col-lg-3(id="searchmenu_orient")
        
          .sidemenu-search(id="searchmenu")
            //affix(:scroll-affix="true"  relative-element-selector="#list-group11" :offset="{top: 100, bottom: 50}" style="width: 270px")
              
            UseCasesOverviewFilter(@search="getData()").mt-3
        
        .col-9.col-lg-9
         .ml-5(style="max-width: 1000px")     
          //.row
            .col-6
              div(style="max-width: 800px").text-muted 
              
            .col-6
                .float-right
                    b-form(inline)
                      b-button-group().mr-2
                        b-button(variant="outline-secondary", size="sm", to="?view=list" :active="this.$route.query.view != 'compare'")#list-btn List
                        b-button(variant="outline-secondary", size="sm", to="?view=compare" :active="this.$route.query.view == 'compare'")#comparison-topic-btn Comparison
      
                      //b-btn(variant="outline-primary", size="sm", @click="showModal({project_id: project._id}, null, use_case_cluster)").mr-2#add-topic-btn Create new use case
      
          .row
            .col-12 
                  
                div.mt-2(v-if="topics != null && !topics")
                  | There are no results for your search query. 
            
          template(v-if="this.$route.path != '/topics/usecases/compare'")

              .row.mb-3
                .col-6
                  .small.text-gray Your search has {{total_rows}} hits
                  div(style="max-width: 800px").text-muted 
                  
                .col-6
                    .float-right
                      b-form(inline)
                        b-form-group(label="Group by")
                          b-select(v-model="group_by" @input="getData()", :options="[{'value': 'industry', 'text': 'Industries'}, {'value': 'enterprise_function', 'text': 'Enterprise Functions'}, {'value': 'project', 'text': 'Projects'}]", size="sm")
              
              
              .col-12(v-for="(topics_inner, name) in topics").mb-1
                .row#list-group11.accordion
                  
                  b-card(no-body class="mb-1", style="background-color: transparent").w-100.shadow-none
                    b-card-header(header-tag="header" class="p-0", style="background-color: transparent")
                      b-button(v-b-toggle="'accordion-' + name", variant="link").text-left.w-100
                        
                        h5 {{name.substring(0,name.indexOf("::"))}} 
                        
                          span.text-small ({{topics_inner.length}})

                    b-collapse(:id="'accordion-' + name"  accordion="my-accordion" role="tabpanel")
                      b-card-body().p-2
                        
                        .form-row.no-gutters
                          .col-lg-6(v-for="(topic, index) in topics_inner")
                            //template(v-if="topics[index-1] && topics[index-1]['relations'] && topics[index-1]['relations']['project_ids'][0]['name'] != topic['relations']['project_ids'][0]['name']")
                              .row
                                .col-12 {{topic['relations']['project_ids'][0]['name']}}
                            
                            div(@click="$store.state.sidebar_project.selected_item = topic", style="min-width: 200px").mb-2
                            
                                
                              //a(:href="'/topics/usecases/' +topic._id")
                                  b-card(no-body, style="cursor:pointer")
                              div(no-body, style="background-color: #303030;")  
                                .form-row.p-2
                                  .col-12
                                    b-input-group()
                                      b-check(:data-name="topic.name", @change="toggle_selected(topic)", :checked="$store.state.sidebar_project.selected_list && $store.state.sidebar_project.selected_list.map && $store.state.sidebar_project.selected_list.map(x=>x._id).indexOf(topic._id) > -1")
                                      b.text-nowrap.uc-name {{topic.name}}

              
              //.col-12(v-for="(topics_inner, name) in topics").mb-5
                .row#list-group11
                  .col-12
                    h5.ml-3.mb-3 Project: 
                      b-link(:to="'/projects/'+name.substring(name.indexOf('::')+2)+'/dashboard'") {{name.substring(0,name.indexOf("::"))}} 

                    .form-row
                      .col-md-12.col-lg-6(v-for="(topic, index) in topics_inner")
                        //template(v-if="topics[index-1] && topics[index-1]['relations'] && topics[index-1]['relations']['project_ids'][0]['name'] != topic['relations']['project_ids'][0]['name']")
                          .row
                            .col-12 {{topic['relations']['project_ids'][0]['name']}}
                        
                        .col-md-12(@click="$store.state.sidebar_project.selected_item = topic", style="min-width: 200px").mb-2
                        
                            
                          //a(:href="'/topics/usecases/' +topic._id")
                              b-card(no-body, style="cursor:pointer")
                          div(no-body, style="background-color: #303030;")  
                            .form-row.p-2
                              .col-12
                                b-input-group()
                                  b-check(@change="toggle_selected(topic)", :checked="$store.state.sidebar_project.selected_list.map(x=>x._id).indexOf(topic._id) > -1")
                                  b.text-nowrap.uc-name {{topic.name}}
                              
                              //.col-2
                                .float-right
                                  b-dropdown(size="sm" variant="link" no-caret, right).comment-dd.option-dropdown
                                    template(v-slot:button-content)
                                      i.fas.fa-ellipsis-h.gray-info

                                    b-dropdown-item(size="sm" @click="showModal(pt, topic, use_case_cluster)") Edit
                                  
                                  b-dropdown-item(size="sm" @click="removeFromProject(project, topic)") Delete from project
          .row
              .col-12 
                div(v-show="$route.path == '/topics/usecases/compare'")
                  
                    div(style="width: 100%").mb-2.full-width.mt-3
                      
                        .row
                          .col-12.col-lg-3
                            b-form-group(label="Smart Preset")
                              b-select(size="sm", @change="change_preset(preset_selected)", placeholder="Load preset", v-model="preset_selected", :options="preset_options")
                    
                          .col-12.col-lg-9
                            .float-right
                              b-form(inline, style="width: 100%")
                                b-form-group(label="Implementation Effort (X-Axis)").mr-3
                                  b-select(size="sm", @change="initScatter(topics)", :options="implementation_effort_options", v-model="implementation_effort_selected")

                                b-form-group(label="Customer Value (Y-Axis)")
                                  b-select(size="sm", @change="initScatter(topics)",:options="customer_value_options", v-model="customer_value_selected")
                        
                        .form-row
                          .col-sm-12(style="background-color: transparent")
                            //.yaxislabel(style="white-space: nowrap").small 
                              b {{customer_value_options.filter( x => x.value == customer_value_selected)[0]['text']}} (%)
                            #viz
                            .toolbar
                              b-button-group(vertical)
                                b-btn(@click="zoomIn()", variant="link", size="sm")
                                  i.fas.fa-search-plus()
                                b-btn(@click="zoomOut()", variant="link", size="sm")
                                  i.fas.fa-search-minus()
                        .row
                          .col-12
                            center
                              b(v-if="implementation_effort_selected == 'time_to_market'")
                                b-row(style="max-width: 600px").small
                                  b-col 
                                    i.fas.fa-arrow-left.mr-2
                                    | Slower
                                  b-col.text-center <b>Time to market (month)</b>
                                  b-col 
                                    | Faster
                                    i.fas.fa-arrow-right.ml-2
                              
                              b(v-if="implementation_effort_selected == 'average_running_costs'")
                                b-row(style="max-width: 600px").small
                                  .col-4
                                    i.fas.fa-arrow-left.mr-2
                                    | More expensive
                                  .col-4.text-center <b>Average running Costs (€)</b>
                                  .col-4 
                                    | Less expensive
                                    i.fas.fa-arrow-right.ml-2

                              b(v-if="implementation_effort_selected == 'implementation_costs'")
                                b-row(style="max-width: 600px").small
                                  b-col 
                                    i.fas.fa-arrow-left.mr-2
                                    | More expensive
                                  b-col.text-center <b>Implementation costs (€)</b>
                                  b-col 
                                    | Less expensive
                                    i.fas.fa-arrow-right.ml-2
                        .row.mt-5
                          .col-6
                            b-form-group(label="Evaluation type")
                              b-select(v-model="survey_type_selected", :options="survey_type_options", size="sm", style="max-width: 300px", @change="initScatter(topics)")
                              .mt-1
                                .text-gray.small {{number_evaluations}} use cases of {{total_rows}} have been evaluated
                          .col-6
                            .float-right
                              b-form(inline)
                                b-form-group(label="Color: Number of evaluations").mr-5
                                  div
                                    .box.small(:style="{color: heatMapColorforValue(0.1)}") 1
                                    .box.small(:style="{color: heatMapColorforValue(0.2)}") 2
                                    .box.small(:style="{color: heatMapColorforValue(0.5)}") 5
                                    .box.small(:style="{color: heatMapColorforValue(0.7)}") 7
                                    .box.small(:style="{color: heatMapColorforValue(1)}") 10
                                b-form-group(label="Size: Number of projects")
                                    .dot.small(style="padding: 6px; width: 20px; height: 20px;") 1
                                    .dot.small(style="padding: 8px; width: 25px; height: 25px;") 2
                                    .dot.small(style="padding: 11px; width: 30px; height: 30px;") 5
                                    .dot.small(style="padding: 12px 4px; width: 35px; height: 35px;") &gt; 10
                        
    
</template>

<script>

import Config from '@/config'
import Vue from 'vue'
import UseCaseCard from '@/components/UseCaseCard.vue'
import UseCaseModal from '@/components/UseCaseModal.vue'
import UseCasesOverviewFilter from '@/views/topics/UseCasesOverviewFilter.vue'
import { mapFields } from 'vuex-map-fields';
import VueStickyDirective from '@renatodeleao/vue-sticky-directive'
import * as d3 from "d3";


export default {
  
  name: 'TopicOverview',
  
  data: function () {
      return {
        xScale: null,
        survey_type_options: [{"value": "use_case", "text": "Use Case Assessments"},{"value": "startup", "text": "Startup Surveys"}],
        survey_type_selected: "use_case",
        number_evaluations: 0,
        svg: null,
        sticky_options: {
          topSpacing: 140,
          bottomSpacing: 50 
        },
        loaded: false,
        customer_value_selected: "time_improvements",
        implementation_effort_selected: "time_to_market",
        preset_selected: "quick_wins",

        preset_options: [
          {"value": "quick_wins", "text": "Quick wins"},
          {"value": "low_haning_fruits", "text": "Low hanging fruits"}, 
          {"value": "innovative_solutions", "text": "Innovative solutions"}, 
          {"value": "proven_solutions", "text": "Proven solutions"},
          {"value": "sustainability_enablers", "text": "Sustainability Enablers"}, 

        ],

        preset_mappings: {
          "quick_wins": {"customer_value": "cost_savings", "implementation_effort": "time_to_market"},
          "low_haning_fruits": {"customer_value": "cost_savings", "implementation_effort": "implementation_costs"},
          "innovative_solutions": {"customer_value": "quality_gains", "implementation_effort": "time_to_market"},
          "proven_solutions": {"customer_value": "cost_savings", "implementation_effort": "implementation_costs"},
          "sustainability_enablers": {"customer_value": "sustainability_impact", "implementation_effort": "time_to_market"},

        },

        customer_value_options: [
          {"value": "time_improvements", "text": "Time improvement"}, 
          {"value": "cost_savings", "text": "Cost Savings"}, 
          {"value": "quality_gains", "text": "Quality gains"},
          {"value": "sustainability_impact", "text": "Sustainability impact"}
        ],
        
        implementation_effort_options: [
          {"value": "time_to_market", "text": "Time to market"}, 
          {"value": "average_running_costs", "text": "Average running costs"}, 
          {"value": "implementation_costs", "text": "Implementation costs"}
        ],

        zoom: null,
        topics: null,
        total_rows: null
    }
  },


  beforeDestroy() {
   
  },

  beforeMount() {
  
  },

  mounted: function() {
   
    this.host = Config.config.base_host;

    this.toggle_selected(null, false);
    d3.select("#viz").append("svg")
        .attr("width", 840)
        .attr("height", 400)
        .call(this.responsivefy);
          

    if(this.$route.path == '/topics/usecases/compare' && this.topics) {
        this.initScatter(this.topics);
        this.$eventHub.$emit('resize');
    }


  },


  computed: {

    ...mapFields([
        'usecase.filter.project_id',
        'usecase.filter.keyword',
        'usecase.filter.custom_data',
        'sidebar_project.selected_item',
        'sidebar_project.selected_list',
        'usecase.filter.group_by',
        'usecase.filter.sort_by',
        'usecase.filter.sort_by_group'
    ]),
  },
  
  watch: {
  
    selected_list: function() {  
     
      this.recolor();
    },

    topics: function(value) {
     
      this.initScatter(value);
      this.$eventHub.$emit('resize');
    },

    "$route.path": {
      handler(change) {
        console.log(change);
        this.toggle_selected(null, false);
        if(change == "/topics/usecases/compare") {
          //this.initScatter();
         
          if(this.topics) {
          
            this.initScatter(this.topics);
            this.$eventHub.$emit('resize');
          }

         
        }
      }
    }
  },

  methods: {

    responsivefy(svg) {
        // get container + svg aspect ratio
        console.log(svg, svg.node())
        var container = d3.select(svg.node().parentNode),
            width = parseInt(svg.style("width")),
            height = parseInt(svg.style("height")),
            aspect = width / (height);

        // add viewBox and preserveAspectRatio properties,
        // and call resize so that svg resizes on inital page load
        //svg.attr("viewBox", "0 0 " + width + " " + height)
        //  .attr("perserveAspectRatio", "xMinYMid")
        svg.call(resize);
        
        // to register multiple listeners for same event type, 
        // you need to add namespace, i.e., 'click.foo'
        // necessary if you call invoke this function for multiple svgs
        // api docs: https://github.com/mbostock/d3/wiki/Selections#on
        d3.select(window).on("resize." + container.attr("id"), resize);
        
        var vm = this;
       
        function resize() {
            
            setTimeout(() => {
            
              var targetWidth = parseInt(container.style("width"));
              //var targetHeight = parseInt(container.style("width"));
             
              if(targetWidth) {
               
                svg.attr("width", targetWidth);
                svg.attr("height", Math.round((targetWidth / aspect)));

               
                vm.initScatter(vm.topics);
              }
            }, 0);
           
        }
    },

    change_preset(selected) {
     
      this.customer_value_selected = this.preset_mappings[selected].customer_value;
      this.implementation_effort_selected = this.preset_mappings[selected].implementation_effort;
  
      this.initScatter(this.topics);

    },
    
    toggle_selected(item, add=true) {
      this.$store.state.sidebar_project.selected_item = item;
      
      if(add) {
         if(this.$store.state.sidebar_project && this.$store.state.sidebar_project.selected_list.map((x)=>{return x._id}).indexOf(item._id)>-1) { 
          var index = this.$store.state.sidebar_project.selected_list.map(x => {
            return x._id;
          }).indexOf(item._id);
          
          this.$store.state.sidebar_project.selected_list.splice(index, 1); 

        } else {
          this.$store.state.sidebar_project.selected_list.push(item);
        }
          
      }
     
    },


    getData() {

      

      this.toggle_selected(null, false); 
      this.$store.dispatch("start_loading");
      Vue.http.post(`${Config.config.base_host}/topics_v1/search`, this.$store.state.usecase.filter).then(res => {
        
        this.topics = res.body.rows;
        this.total_rows = res.body.total_rows;
        this.$store.dispatch("stop_loading");
      });
    },

    addUseCases(project) {
        this.$store.state.sidebar_use_cases.project = project;
    },

  
    

    showModal(pt, topic, group) {
      this.$refs.topicUseCaseModal.open(pt, topic, group);
    },
    
    heatMapColorforValue(value){  
        var h = (value) * 240
        return "hsla(" + h + ", 40%, 60%, 80%)";
    },

    recolor() {

      this.data.forEach((it) => {
        let topic = it.topic;
        this.svg.selectAll('#s'+topic._id).style("fill", this.heatMapColorforValue(topic.index_space.surveys ? Math.min(1, topic.index_space.surveys.length/10) : 0)).style("stroke-width", 3);
        if(this.$store.state.sidebar_project && this.$store.state.sidebar_project.selected_list && this.$store.state.sidebar_project.selected_list.map((x)=>{return x._id}).indexOf(topic._id)>-1) {
              this.svg.selectAll('#s'+topic._id).style("stroke", 'white');
            } else {    
               this.svg.selectAll('#s'+topic._id).style("stroke", 'transparent');
            }
        if(this.selected_item && this.selected_item._id == topic._id) {
       
          //this.svg.selectAll('#s'+topic._id).style("fill", 'transparent');
          this.svg.selectAll('#s'+topic._id).style("stroke", 'white');
        } else {    
            console.log();

        }


      });
      
    },

    redraw_scatter() {
        console.log("Redraw");

        var margin = {top: 10, left: 20};

          // Draw Datapoints
      var points_g = this.svg.append("g")
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
        .attr("clip-path", "url(#clip)")
        .classed("points_g", true);
      
      this.points = points_g.selectAll("circle").data(this.data).enter().append("circle")
            .attr('cx', (d) => {return this.xScale(d.x)})
            .attr('cy', (d) =>  {return this.yScale(d.y)})
            .style("fill", function(d){ return d.color; })
            .attr("cursor", "pointer")
            .attr("id", function(d){ return "s"+d.topic._id; })
            .attr('r', function(d){ return d.r; })
            .on("click", (d, data) => {
               
                tip.transition()
                .delay(0)
                .style('display', 'none');
               
                this.toggle_selected(data.topic, false);
                
                this.recolor();

             }).on('mouseover', function(d, data) {
                tip.transition().duration(0);
                tip.html(data.topic.name);
                tip.style('top', d.y - 20 + 'px');
                tip.style('left', d.x + 10 + 'px');
                tip.style('display', 'block');
              })
              .on('mouseout', function() {
                tip.transition()
                .delay(1)
                .style('display', 'none');
              })
        
      var tip = d3.select('body')
        .append('div')
        .attr('class', 'tip')
        .html('...')
       
        .style('padding', '3px')
        .style('position', 'absolute')
        .style('display', 'none')
        .style('color', 'white')
        .on('mouseover', function() {
          tip.transition().duration(0);
        })
        .on('mouseout', function() {
          tip.style('display', 'none');
        });

         this.recolor();
    },

    zoomed(event) {
      // create new scale ojects based on event
          this.new_xScale = event.transform.rescaleX(this.xScale);
          this.new_yScale = event.transform.rescaleY(this.yScale);
    
          this.gX.call(this.xAxis.scale(this.new_xScale));
          this.gY.call(this.yAxis.scale(this.new_yScale));

          this.points.data(this.data)

          .attr('cx', (d) =>  {return this.new_xScale(d.x)})
          .attr('cy', (d) =>  {return this.new_yScale(d.y)})
          
      },

    zoomIn() {
      this.svg.transition().call(this.zoom.scaleBy, 1.5);
    },

    zoomOut() {
      this.svg.transition().call(this.zoom.scaleBy, 0.75);
    },

    initScatter(topics) {
      
      var paras = document.getElementsByClassName("tip");
      while(paras[0]){ paras[0].parentNode.removeChild(paras[0])}
      paras = document.getElementsByClassName("addmenu");
      while(paras[0]){ paras[0].parentNode.removeChild(paras[0])}
      
      if(!topics) return;
     
      this.data = this.genRandomData(this.$store, topics);
      let arX = this.data.map(x=>x.topic.x);
      let arY = this.data.map(x=>x.topic.y);
      

      let maxX = Math.round(Math.max(...arX)* 1.5);
      let maxY = Math.round(Math.max(...arY)*1.5);

     
      d3.selectAll("svg > *").remove();
     
      this.svg = d3.select("svg");
      console.log("SVG", this.svg);

      var width = this.svg.attr("width"),
       height = this.svg.attr("height");

      width = 1*width;
      height = 1*height;
      var margin = {top: 20, bottom: 20, left: 20};
      
 
      // create a clipping region 
      this.svg.append("defs").append("clipPath")
          .attr("id", "clip")
        .append("rect")
          .attr("width", width)
          .attr("height", height)
        
      // create scale objects
      this.xScale = d3.scaleLinear()
        .domain([0, maxX+10])
        .range([width, 0]);

      this.yScale = d3.scaleLinear()
        .domain([0, maxY+10])
        .range([height, 0]);

      // create axis objects
      this.xAxis = d3.axisBottom(this.xScale)
        .ticks(20, "s").tickFormat(function (d) { 
          if (d === 0) return d; // No label for '0'
          else if (d < 0) return '' // No nagative labels
          return d;
        });
      this.yAxis = d3.axisLeft(this.yScale)
        .ticks(20, "s").tickFormat(function (d) { 
          if (d === 0) return d; // No label for '0'
          else if (d < 0) return '' // No nagative labels
          return d;
        });


      this.xAxis2 = d3.axisBottom(this.xScale)
        .ticks(20, "s").tickFormat(function (d) { 
          if (d === 0) return ''; // No label for '0'
          else if (d < 0) return '' // No nagative labels
          return d;
        });

      this.yAxis2 = d3.axisLeft(this.yScale)
        .ticks(20, "s").tickFormat(function (d) { 
          if (d === 0) return ''; // No label for '0'
          else if (d < 0) return '' // No nagative labels
          return d;
        });
    


      // Draw Axis
      this.gX = this.svg.append('g')
        .attr('transform', 'translate(' + margin.left + ',' + (height-margin.top) + ')')
        .call(this.xAxis).call(this.xAxis2).call(g => g.append("text")
        .attr("x", width - 20)
        .attr("y", margin.bottom - 30)
        .attr("font-weight", 600)
        .attr("fill", "currentColor")
        .attr("text-anchor", "end")
        .text(this.implementation_effort_options.filter(x => x.value == this.implementation_effort_selected)[0].text + " ⇨"));

      this.gY = this.svg.append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
        .call(this.yAxis).call(this.yAxis2).call(g => g.append("text")
        .attr("x", 10)
        .attr("y", 10)
        .attr("font-weight", 600)
        .attr("fill", "currentColor")
        .attr("text-anchor", "start")
        .text("⇧ " + this.customer_value_options.filter(x => x.value == this.customer_value_selected)[0].text + " (%)"));
      
     
     function makeGrid() {
      // svg.insert("g", '#scatterplot')
      //   .attr("class", "grid grid-x")
      //   .attr("transform", "translate(0," + height + ")")
      //   .call(xAxis
      //     .tickSize(-height)
      //     .tickFormat(''));

      // svg.insert("g", '#scatterplot')
      //   .attr("class", "grid grid-y")
      //   .call(yAxis
      //     .tickSize(-width)
      //     .tickFormat(''));

      // svg.selectAll('.grid')
      //   .selectAll('line')
      //   .attr('stroke', 'gray');
    }

    
      makeGrid()

      this.redraw_scatter();
      let first = true;
      // Pan and zoom
      if(!this.zoom) {
         this.zoom = d3.zoom()
          .scaleExtent([0.8, 5])
          //.extent([[0, 0], [width, height]])
          .clickDistance(1000)
        first = true;          
      }

      this.zoom.on("zoom", this.zoomed)

      if(first) {
        this.svg.transition().call(this.zoom.scaleBy, 0.1);
      }

      this.new_xScale = null;
      this.new_yScale = null;

      this.svg.call(this.zoom)
      
    },

    genRandomData (store, topics){
        var data = [];
        var datapoint = {};
        Object.keys(topics).forEach((name) => {
          let subtopics = topics[name];
          subtopics.forEach(topic => {
            datapoint = {};
            if(!data.map(y=>y.topic._id).includes(topic._id)) {
              if(topic.index_space && (topic.index_space['use_case_assessment_aggregation'] || topic.index_space['startup_assessment_aggregation']) && topic.index_space.surveys && topic.index_space.surveys.length > 0) {
                  
                  let metric_x = "mean";
                  let metric_y = "mean";
                  let out = false;
               
                  topic.evaluations = topic.index_space[this.survey_type_selected + '_assessment_aggregation'];
                  try {
                    topic.x = topic.evaluations[this.implementation_effort_selected][metric_x];
                    topic.y = topic.evaluations[this.customer_value_selected][metric_y];
                  } catch {
                    out = true;
                  }
                  datapoint["x"] =  topic.x;
                  datapoint["y"] =  topic.y;
                 
                  if(!topic.index_space.project_organizations || topic.index_space.project_organizations.length <= 1) {
                    datapoint["r"] = 10;
                  } else if(topic.index_space.project_organizations.length < 5) {
                    datapoint["r"] = 12;
                  } else if(topic.index_space.project_organizations.length < 10) {
                    datapoint["r"] = 15;
                  } else {
                    datapoint["r"] = 17;
                  }
                  

                  if(store.state.sidebar_project.selected_list && store.state.sidebar_project.selected_list.map((x)=>{return x._id}).indexOf(topic._id)>-1) {
                    datapoint["color"] = "green";
                  } else {
                    datapoint["color"] = "#fff";
                  }
                
                  
                  datapoint['topic'] = topic;
                  if(!out) data.push(datapoint);
                }
              }
          });
        });
        this.number_evaluations = data.length;
        return data
      }

  },

  directives: {
    "sticky": VueStickyDirective
  },


  components: {
      UseCaseCard,
      UseCaseModal,
      UseCasesOverviewFilter
  }
}

</script>
<style lang="scss" scoped>

@import '@/_vars.scss';
    a {
        text-decoration: none !important;
    }

    .box {
      display: inline-block;
      padding: 2px;
    }

  .dot {
  line-height: 0.875em;
  color: black;
  background-color: #4B4B4A;
  color: #fff;
  border-radius: 50%;
  display: inline-block;
  font-size: 0.75em;
  margin-right: 5px;

}

      .yaxislabel {
          -webkit-transform: rotate(-90deg);
          -moz-transform: rotate(-90deg);
          -o-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
      }


    .uc-name {
      cursor: pointer;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 300px;
      display: inline-block;
    }


    .toolbar {
      position: absolute;
      top: 0;
      right: 0;
      background-color: $main-view-background-color;
      a {
        color: $gray-800;
      }
    }
</style>