import { render, staticRenderFns } from "./TopicOverview.vue?vue&type=template&id=24626e31&scoped=true&lang=pug"
import script from "./TopicOverview.vue?vue&type=script&lang=js"
export * from "./TopicOverview.vue?vue&type=script&lang=js"
import style0 from "./TopicOverview.vue?vue&type=style&index=0&id=24626e31&prod&lang=scss&scoped=true"
import style1 from "./TopicOverview.vue?vue&type=style&index=1&id=24626e31&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24626e31",
  null
  
)

export default component.exports